import './App.scss';
import LeafletMap from './components/LeafletMap';
import PostcodeChecker from './components/PostcodeChecker';
import { MapProvider } from './components/MapContext';
import { useState } from 'react';

function App () {
	return (
		<MapProvider>
			<div className="App">
				<div className="survey">
					<div className="survey__header">
						<PostcodeChecker />
					</div>	
					<LeafletMap />	
				</div>
			</div>
		</MapProvider>
	);
}

export default App;
