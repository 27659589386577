export const d20_50_poly = {
    "type": "FeatureCollection",
    "name": "Decrease_20-50_Poly",
    "features": [{
        "type": "Feature",
        "properties": {
            "NAME": "-49",
            "LAYER": "Contour Line, Intermediate",
            "ELEVATION": -49,
            "CLOSED_CON": "YES"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [-0.3754255, 51.3828171],
                        [-0.3754078, 51.3785864],
                        [-0.3753629, 51.3783251],
                        [-0.3751667, 51.3778793],
                        [-0.3750292, 51.3774316],
                        [-0.3749437, 51.3766178],
                        [-0.374791, 51.3762299],
                        [-0.3744679, 51.3757688],
                        [-0.373981, 51.3752075],
                        [-0.3734447, 51.3744041],
                        [-0.3727705, 51.3738103],
                        [-0.3727214, 51.373601],
                        [-0.3729152, 51.3731098],
                        [-0.3728925, 51.3728985],
                        [-0.3726333, 51.3726999],
                        [-0.3721455, 51.3725591],
                        [-0.3718212, 51.3725126],
                        [-0.371448, 51.3725476],
                        [-0.3711017, 51.3726834],
                        [-0.3708231, 51.3728784],
                        [-0.3704148, 51.3735365],
                        [-0.3702224, 51.3737341],
                        [-0.3701077, 51.3737963],
                        [-0.3697751, 51.3738678],
                        [-0.3690019, 51.3739198],
                        [-0.3686873, 51.3739753],
                        [-0.3682053, 51.3742103],
                        [-0.3674918, 51.3746621],
                        [-0.3666874, 51.3750688],
                        [-0.3657187, 51.3758056],
                        [-0.3588994, 51.3801042],
                        [-0.3577856, 51.3807564],
                        [-0.355053, 51.3825489],
                        [-0.3548691, 51.3827735],
                        [-0.3543907, 51.3836621],
                        [-0.3536662, 51.3843119],
                        [-0.3534755, 51.3845355],
                        [-0.3527134, 51.3859784],
                        [-0.3518256, 51.3869098],
                        [-0.3512218, 51.3878871],
                        [-0.3504122, 51.3886556],
                        [-0.3498057, 51.3894397],
                        [-0.3490386, 51.3905493],
                        [-0.3486943, 51.3914523],
                        [-0.3481076, 51.3922268],
                        [-0.3474702, 51.3932124],
                        [-0.3472784, 51.3940255],
                        [-0.3472235, 51.395141],
                        [-0.3471363, 51.3957296],
                        [-0.3470269, 51.3960098],
                        [-0.3466708, 51.3965871],
                        [-0.346537, 51.3970238],
                        [-0.3464491, 51.3994966],
                        [-0.3458443, 51.4007748],
                        [-0.3456615, 51.4013887],
                        [-0.3457192, 51.4064847],
                        [-0.3457753, 51.4068062],
                        [-0.3463569, 51.4084167],
                        [-0.3463845, 51.4101825],
                        [-0.3464517, 51.4106052],
                        [-0.3466143, 51.4111189],
                        [-0.3470764, 51.4122007],
                        [-0.3470529, 51.4137561],
                        [-0.3471906, 51.4146062],
                        [-0.3475403, 51.4156255],
                        [-0.3477146, 51.4166579],
                        [-0.3482267, 51.417529],
                        [-0.3484643, 51.4184589],
                        [-0.3487756, 51.4191551],
                        [-0.3488217, 51.419474],
                        [-0.3487575, 51.4200588],
                        [-0.3488061, 51.4203637],
                        [-0.3493832, 51.4212495],
                        [-0.3499607, 51.4229065],
                        [-0.3506038, 51.4238326],
                        [-0.3510737, 51.4246173],
                        [-0.3515212, 51.4258931],
                        [-0.3516506, 51.4266302],
                        [-0.3516338, 51.4274259],
                        [-0.3510701, 51.4288099],
                        [-0.3509382, 51.4290247],
                        [-0.3508303, 51.4291062],
                        [-0.3503306, 51.429234],
                        [-0.3480207, 51.4293172],
                        [-0.344823, 51.4292118],
                        [-0.3423264, 51.428946],
                        [-0.3403177, 51.4285338],
                        [-0.3356746, 51.4279034],
                        [-0.3339169, 51.4275035],
                        [-0.3305757, 51.4271695],
                        [-0.328596, 51.4268918],
                        [-0.3270804, 51.4265518],
                        [-0.3259758, 51.4263492],
                        [-0.3245242, 51.4261808],
                        [-0.3231398, 51.4259381],
                        [-0.3214297, 51.4255024],
                        [-0.3207968, 51.4253911],
                        [-0.3158177, 51.4254518],
                        [-0.3137244, 51.4253592],
                        [-0.3105397, 51.425081],
                        [-0.308184, 51.4246208],
                        [-0.3065313, 51.4243733],
                        [-0.3037727, 51.4242119],
                        [-0.3021205, 51.4240237],
                        [-0.300881, 51.4239718],
                        [-0.2992828, 51.4237309],
                        [-0.2963945, 51.4234948],
                        [-0.2948965, 51.4233211],
                        [-0.2936371, 51.4232774],
                        [-0.2919976, 51.4233056],
                        [-0.2907318, 51.4232769],
                        [-0.2892675, 51.4230842],
                        [-0.2877852, 51.4229631],
                        [-0.2863645, 51.4226524],
                        [-0.28355, 51.4224339],
                        [-0.2820824, 51.4223376],
                        [-0.2792007, 51.4223066],
                        [-0.2762076, 51.4221842],
                        [-0.2731449, 51.4223509],
                        [-0.2695035, 51.4223639],
                        [-0.2689252, 51.4223959],
                        [-0.2682877, 51.4224943],
                        [-0.2664988, 51.4228779],
                        [-0.2648198, 51.4230661],
                        [-0.2640916, 51.423258],
                        [-0.2633431, 51.4235801],
                        [-0.2628328, 51.4239437],
                        [-0.2622554, 51.4244778],
                        [-0.2609745, 51.4259255],
                        [-0.2607299, 51.4263625],
                        [-0.2606834, 51.4270885],
                        [-0.2610075, 51.4281943],
                        [-0.2615208, 51.4291959],
                        [-0.2618635, 51.4296496],
                        [-0.2621369, 51.4299203],
                        [-0.2626797, 51.4302583],
                        [-0.2638071, 51.4306718],
                        [-0.2643309, 51.4309244],
                        [-0.2665551, 51.4323419],
                        [-0.2677784, 51.4329419],
                        [-0.2689124, 51.4333638],
                        [-0.2695148, 51.4337416],
                        [-0.2698607, 51.433898],
                        [-0.2703621, 51.4340149],
                        [-0.2715664, 51.4341944],
                        [-0.2719065, 51.4343198],
                        [-0.2724759, 51.4346343],
                        [-0.2728183, 51.4347636],
                        [-0.2732841, 51.4348546],
                        [-0.2744513, 51.43498],
                        [-0.2749153, 51.4350723],
                        [-0.2756401, 51.4352921],
                        [-0.2765106, 51.4356245],
                        [-0.2770549, 51.4357751],
                        [-0.2773728, 51.4358194],
                        [-0.2779405, 51.4358216],
                        [-0.2787148, 51.4357631],
                        [-0.2790871, 51.4356867],
                        [-0.2792358, 51.4356113],
                        [-0.2793022, 51.4355104],
                        [-0.279318, 51.4354081],
                        [-0.279195, 51.4352254],
                        [-0.2777312, 51.4342305],
                        [-0.2771427, 51.4339587],
                        [-0.2760681, 51.4337207],
                        [-0.2755576, 51.4335644],
                        [-0.2750277, 51.4332268],
                        [-0.2729129, 51.4320716],
                        [-0.269607, 51.4299706],
                        [-0.2692678, 51.4296234],
                        [-0.2686131, 51.4283374],
                        [-0.2684779, 51.4279223],
                        [-0.2684056, 51.4273883],
                        [-0.2684919, 51.4271511],
                        [-0.2686179, 51.427024],
                        [-0.2695209, 51.4264883],
                        [-0.2701733, 51.4259865],
                        [-0.2704687, 51.4258387],
                        [-0.2712015, 51.4256089],
                        [-0.2722395, 51.4251997],
                        [-0.2736049, 51.4250079],
                        [-0.2757102, 51.4245646],
                        [-0.2762564, 51.4244818],
                        [-0.2784485, 51.424467],
                        [-0.2793235, 51.4245122],
                        [-0.2832321, 51.4252666],
                        [-0.2837449, 51.4253241],
                        [-0.2849165, 51.4253802],
                        [-0.2860976, 51.4255344],
                        [-0.2877159, 51.4259921],
                        [-0.2903361, 51.4263688],
                        [-0.2918348, 51.4267118],
                        [-0.2923429, 51.4267645],
                        [-0.2935639, 51.4268071],
                        [-0.2950566, 51.4269873],
                        [-0.2977061, 51.427527],
                        [-0.2999216, 51.4278993],
                        [-0.3017368, 51.4283502],
                        [-0.3031597, 51.4286007],
                        [-0.3046572, 51.4290451],
                        [-0.3076562, 51.4297123],
                        [-0.3089433, 51.4301382],
                        [-0.3105612, 51.4304771],
                        [-0.3119644, 51.4309534],
                        [-0.3133472, 51.431158],
                        [-0.3138065, 51.4312713],
                        [-0.3153615, 51.4317975],
                        [-0.3172063, 51.4323541],
                        [-0.3184629, 51.4328047],
                        [-0.3203075, 51.4333249],
                        [-0.3218513, 51.4338275],
                        [-0.3233512, 51.4341698],
                        [-0.3236634, 51.4343016],
                        [-0.324377, 51.4347557],
                        [-0.324771, 51.4349388],
                        [-0.3261264, 51.4352357],
                        [-0.3271224, 51.4357454],
                        [-0.3275582, 51.4359259],
                        [-0.3279819, 51.4360218],
                        [-0.329018, 51.4361532],
                        [-0.3300203, 51.4366535],
                        [-0.3304186, 51.4367868],
                        [-0.3318703, 51.4370068],
                        [-0.3332864, 51.437488],
                        [-0.3346897, 51.43775],
                        [-0.3360431, 51.4383215],
                        [-0.3372792, 51.4385597],
                        [-0.3377061, 51.4386778],
                        [-0.3389372, 51.4392391],
                        [-0.3404439, 51.4395563],
                        [-0.3415733, 51.4400916],
                        [-0.3423325, 51.4403941],
                        [-0.3429322, 51.4408216],
                        [-0.3432435, 51.4409857],
                        [-0.3436006, 51.44107],
                        [-0.3443947, 51.4411159],
                        [-0.3448833, 51.4412006],
                        [-0.3453048, 51.4413786],
                        [-0.3458938, 51.4417857],
                        [-0.3461276, 51.4418952],
                        [-0.3465255, 51.4419892],
                        [-0.3472272, 51.4420689],
                        [-0.3475201, 51.4421488],
                        [-0.3485858, 51.4427197],
                        [-0.348949, 51.4428565],
                        [-0.3500619, 51.4430234],
                        [-0.3503528, 51.4431019],
                        [-0.3515189, 51.4436959],
                        [-0.3532325, 51.4442257],
                        [-0.3545284, 51.4448769],
                        [-0.3560381, 51.4454171],
                        [-0.3571595, 51.4459571],
                        [-0.358653, 51.446474],
                        [-0.3593894, 51.4470368],
                        [-0.3600085, 51.4474107],
                        [-0.3609201, 51.4485527],
                        [-0.3617274, 51.4491918],
                        [-0.3620583, 51.4497019],
                        [-0.3625091, 51.4506663],
                        [-0.3636474, 51.4519845],
                        [-0.3640991, 51.4523621],
                        [-0.3650871, 51.4528786],
                        [-0.3652615, 51.4530801],
                        [-0.3655209, 51.453585],
                        [-0.3656555, 51.4537305],
                        [-0.3658637, 51.4538494],
                        [-0.3661941, 51.4538781],
                        [-0.3663801, 51.453848],
                        [-0.3666658, 51.4537315],
                        [-0.3667111, 51.4536442],
                        [-0.3665711, 51.4530174],
                        [-0.3665942, 51.4527086],
                        [-0.3670494, 51.4507551],
                        [-0.3671765, 51.4503586],
                        [-0.3673562, 51.4500194],
                        [-0.3675241, 51.4498639],
                        [-0.3679235, 51.4496826],
                        [-0.3685327, 51.4495815],
                        [-0.3688905, 51.449586],
                        [-0.3702264, 51.4499427],
                        [-0.3716512, 51.450257],
                        [-0.3720313, 51.4504464],
                        [-0.3727683, 51.4509195],
                        [-0.3731051, 51.4510659],
                        [-0.37455, 51.4513928],
                        [-0.3755979, 51.4518794],
                        [-0.376888, 51.4524036],
                        [-0.378449, 51.4529173],
                        [-0.3809239, 51.4538601],
                        [-0.3817299, 51.4542297],
                        [-0.3837056, 51.4553802],
                        [-0.3841694, 51.4557952],
                        [-0.3845173, 51.4566064],
                        [-0.3844308, 51.4571376],
                        [-0.3842025, 51.4575916],
                        [-0.3837843, 51.4579582],
                        [-0.3818041, 51.4593606],
                        [-0.3810524, 51.4601147],
                        [-0.3788333, 51.4613942],
                        [-0.3779677, 51.4619998],
                        [-0.3775105, 51.4624358],
                        [-0.3769696, 51.4630965],
                        [-0.3767432, 51.4635765],
                        [-0.376736, 51.4637985],
                        [-0.3772616, 51.4648686],
                        [-0.3777204, 51.4655907],
                        [-0.3784631, 51.4664128],
                        [-0.3790891, 51.4672365],
                        [-0.3795736, 51.4676613],
                        [-0.3806085, 51.4682389],
                        [-0.3824864, 51.4690868],
                        [-0.3833365, 51.4695503],
                        [-0.3843457, 51.4699543],
                        [-0.3853238, 51.4704188],
                        [-0.3866592, 51.4708838],
                        [-0.3880811, 51.4712409],
                        [-0.3911342, 51.4715048],
                        [-0.3926094, 51.4714711],
                        [-0.3936869, 51.471489],
                        [-0.3943728, 51.4714398],
                        [-0.3955954, 51.471186],
                        [-0.3969374, 51.4710718],
                        [-0.3985795, 51.4706418],
                        [-0.3994416, 51.4705055],
                        [-0.4001631, 51.4703392],
                        [-0.4047308, 51.4689728],
                        [-0.406715, 51.468471],
                        [-0.408507, 51.4679523],
                        [-0.4103601, 51.4676325],
                        [-0.4124838, 51.4673414],
                        [-0.4132605, 51.4672757],
                        [-0.4145885, 51.4672364],
                        [-0.4168993, 51.4672601],
                        [-0.4184743, 51.4673584],
                        [-0.4218467, 51.467461],
                        [-0.424435, 51.467603],
                        [-0.4281739, 51.4677215],
                        [-0.4301303, 51.4678797],
                        [-0.4317449, 51.4682293],
                        [-0.4329061, 51.4683568],
                        [-0.4344355, 51.4683877],
                        [-0.4370669, 51.4683759],
                        [-0.4377625, 51.4684122],
                        [-0.4401294, 51.468642],
                        [-0.4416636, 51.4689803],
                        [-0.4430522, 51.4690543],
                        [-0.4444904, 51.4692891],
                        [-0.4455654, 51.4693099],
                        [-0.4460722, 51.4693551],
                        [-0.448636, 51.4699887],
                        [-0.4492851, 51.4700741],
                        [-0.4516563, 51.4702805],
                        [-0.4543341, 51.470778],
                        [-0.4574303, 51.47076],
                        [-0.4591498, 51.470902],
                        [-0.461682, 51.4709166],
                        [-0.4626701, 51.471041],
                        [-0.4631756, 51.4710693],
                        [-0.4649314, 51.4709305],
                        [-0.4658505, 51.4709242],
                        [-0.466483, 51.4710132],
                        [-0.4677857, 51.4714454],
                        [-0.4682896, 51.4715422],
                        [-0.4687641, 51.4715845],
                        [-0.4692203, 51.4715591],
                        [-0.4703082, 51.4712882],
                        [-0.4708525, 51.4712451],
                        [-0.4743116, 51.4712399],
                        [-0.4760758, 51.4713155],
                        [-0.4771599, 51.4713217],
                        [-0.4790303, 51.4712423],
                        [-0.4846903, 51.4712421],
                        [-0.4864307, 51.4711251],
                        [-0.4901415, 51.471149],
                        [-0.490614, 51.4711082],
                        [-0.4912354, 51.4709848],
                        [-0.4919045, 51.4707373],
                        [-0.4919675, 51.4706461],
                        [-0.4919912, 51.4704107],
                        [-0.491886, 51.4700184],
                        [-0.491734, 51.4697118],
                        [-0.4907858, 51.4686608],
                        [-0.4906047, 51.4685196],
                        [-0.4902893, 51.4684185],
                        [-0.4891056, 51.4683759],
                        [-0.4824069, 51.4683174],
                        [-0.4820927, 51.4682727],
                        [-0.4810391, 51.4680133],
                        [-0.4799647, 51.4678596],
                        [-0.4790121, 51.4675944],
                        [-0.4776352, 51.4675165],
                        [-0.4762593, 51.4675884],
                        [-0.4749508, 51.4672493],
                        [-0.4740939, 51.4671796],
                        [-0.4732073, 51.4671688],
                        [-0.4722579, 51.4672079],
                        [-0.4710305, 51.4673042],
                        [-0.4697521, 51.4675018],
                        [-0.4677712, 51.4675731],
                        [-0.4661283, 51.4675776],
                        [-0.4623679, 51.4675117],
                        [-0.4620443, 51.4674648],
                        [-0.4618456, 51.467384],
                        [-0.461742, 51.4670257],
                        [-0.4616085, 51.4668651],
                        [-0.4607822, 51.4666191],
                        [-0.4606746, 51.4664568],
                        [-0.4606865, 51.4663355],
                        [-0.4607493, 51.4661081],
                        [-0.4608405, 51.4659937],
                        [-0.4619036, 51.4660407],
                        [-0.4624285, 51.466147],
                        [-0.4626596, 51.4662405],
                        [-0.4631427, 51.4666094],
                        [-0.4634192, 51.4666927],
                        [-0.4638132, 51.4666278],
                        [-0.4645374, 51.4663054],
                        [-0.465103, 51.4661047],
                        [-0.465812, 51.4660069],
                        [-0.4696464, 51.4659303],
                        [-0.4717949, 51.465817],
                        [-0.4721893, 51.4657297],
                        [-0.473177, 51.4652871],
                        [-0.4737963, 51.4651582],
                        [-0.475923, 51.4650217],
                        [-0.4770339, 51.4649085],
                        [-0.4783056, 51.4645736],
                        [-0.4802461, 51.464201],
                        [-0.4808618, 51.4641255],
                        [-0.4824397, 51.4640649],
                        [-0.4840311, 51.4638066],
                        [-0.4860439, 51.4636583],
                        [-0.486416, 51.4635679],
                        [-0.4871338, 51.4633107],
                        [-0.4875078, 51.463142],
                        [-0.488025, 51.4627759],
                        [-0.4882568, 51.4626781],
                        [-0.4889691, 51.4624812],
                        [-0.4893043, 51.4622487],
                        [-0.4896188, 51.4619143],
                        [-0.490335, 51.4617043],
                        [-0.4906865, 51.4615091],
                        [-0.4908455, 51.4612822],
                        [-0.4909811, 51.4609419],
                        [-0.4912015, 51.4606919],
                        [-0.4936285, 51.459051],
                        [-0.4941626, 51.458466],
                        [-0.4945975, 51.4581815],
                        [-0.4952438, 51.4579253],
                        [-0.495807, 51.4578188],
                        [-0.4966545, 51.4577176],
                        [-0.497733, 51.4576973],
                        [-0.4983166, 51.4576292],
                        [-0.4987024, 51.4575234],
                        [-0.4992534, 51.457295],
                        [-0.4994894, 51.4568537],
                        [-0.499524, 51.4564429],
                        [-0.4991488, 51.4556162],
                        [-0.4989224, 51.4552978],
                        [-0.4985786, 51.4550799],
                        [-0.4976682, 51.4547352],
                        [-0.4971288, 51.454389],
                        [-0.4963333, 51.4539847],
                        [-0.4957539, 51.4535528],
                        [-0.4954469, 51.4534118],
                        [-0.4950193, 51.453357],
                        [-0.4943021, 51.4533465],
                        [-0.4938356, 51.4532656],
                        [-0.4934395, 51.4530762],
                        [-0.4928486, 51.4526709],
                        [-0.492583, 51.4525666],
                        [-0.4923154, 51.4525153],
                        [-0.4855313, 51.4525317],
                        [-0.4849759, 51.4524224],
                        [-0.4842481, 51.4520877],
                        [-0.4839479, 51.4520224],
                        [-0.4835999, 51.4521153],
                        [-0.482828, 51.4524667],
                        [-0.4824218, 51.4525614],
                        [-0.4819132, 51.452599],
                        [-0.4799895, 51.4526246],
                        [-0.4793993, 51.4527045],
                        [-0.4776705, 51.4532128],
                        [-0.4768987, 51.4534871],
                        [-0.4739313, 51.4544147],
                        [-0.4733516, 51.4544863],
                        [-0.4714889, 51.4545379],
                        [-0.4708782, 51.4546187],
                        [-0.4704473, 51.4547834],
                        [-0.4697483, 51.455227],
                        [-0.4693057, 51.4553727],
                        [-0.4636512, 51.4554111],
                        [-0.4625978, 51.4555273],
                        [-0.4611139, 51.4559026],
                        [-0.4604823, 51.4559166],
                        [-0.4596651, 51.4558433],
                        [-0.4593599, 51.4557731],
                        [-0.4591442, 51.4556624],
                        [-0.4588139, 51.4553291],
                        [-0.458399, 51.4551577],
                        [-0.4577891, 51.4550429],
                        [-0.4568478, 51.4549647],
                        [-0.4564856, 51.4548647],
                        [-0.456234, 51.4547565],
                        [-0.4558954, 51.4544267],
                        [-0.4554569, 51.4542621],
                        [-0.4549183, 51.4541326],
                        [-0.4539754, 51.4540342],
                        [-0.4536129, 51.4539488],
                        [-0.4532603, 51.4538083],
                        [-0.4529659, 51.4535477],
                        [-0.4527666, 51.4534488],
                        [-0.4520475, 51.4532237],
                        [-0.451062, 51.4531058],
                        [-0.4485795, 51.453031],
                        [-0.4478986, 51.452969],
                        [-0.447244, 51.4528205],
                        [-0.446678, 51.4524782],
                        [-0.4462704, 51.4522872],
                        [-0.4458191, 51.4521812],
                        [-0.4447729, 51.4521044],
                        [-0.4421296, 51.4520566],
                        [-0.4409133, 51.4519688],
                        [-0.4401201, 51.4518467],
                        [-0.4393274, 51.4515899],
                        [-0.4382376, 51.4514899],
                        [-0.4373763, 51.4512151],
                        [-0.4370583, 51.4511624],
                        [-0.4338357, 51.4508851],
                        [-0.4317433, 51.450819],
                        [-0.4310363, 51.4507619],
                        [-0.4294047, 51.450567],
                        [-0.4279123, 51.4502942],
                        [-0.4269744, 51.4501723],
                        [-0.4233445, 51.4499433],
                        [-0.420188, 51.4495973],
                        [-0.4169561, 51.4491176],
                        [-0.4150582, 51.4489809],
                        [-0.413805, 51.4487971],
                        [-0.4118443, 51.4483158],
                        [-0.4105517, 51.4478769],
                        [-0.4093624, 51.4476104],
                        [-0.4086005, 51.4473219],
                        [-0.4078037, 51.4471245],
                        [-0.4066101, 51.4467216],
                        [-0.4050153, 51.446251],
                        [-0.4040607, 51.4458636],
                        [-0.403436, 51.4457486],
                        [-0.4021951, 51.445672],
                        [-0.4016529, 51.4455168],
                        [-0.4006295, 51.4450328],
                        [-0.3993912, 51.4445513],
                        [-0.3988516, 51.4442982],
                        [-0.3978098, 51.443644],
                        [-0.3966414, 51.4430182],
                        [-0.3960786, 51.442825],
                        [-0.3948494, 51.4425556],
                        [-0.3938952, 51.4421586],
                        [-0.393172, 51.4417978],
                        [-0.3921682, 51.4411918],
                        [-0.390784, 51.440589],
                        [-0.389874, 51.4400461],
                        [-0.3891755, 51.4397994],
                        [-0.3877011, 51.4394422],
                        [-0.3864515, 51.4389959],
                        [-0.3860868, 51.4387814],
                        [-0.3853933, 51.4382259],
                        [-0.3838564, 51.437262],
                        [-0.3835774, 51.4371708],
                        [-0.3833946, 51.4371516],
                        [-0.3824864, 51.4371784],
                        [-0.3820207, 51.4371152],
                        [-0.3803406, 51.4364846],
                        [-0.3798048, 51.4361552],
                        [-0.3790505, 51.4354056],
                        [-0.3787634, 51.4352431],
                        [-0.3784079, 51.4351114],
                        [-0.3764579, 51.4346158],
                        [-0.3758957, 51.4344284],
                        [-0.3750341, 51.4340735],
                        [-0.37433, 51.4339097],
                        [-0.3739211, 51.4338426],
                        [-0.3734961, 51.4338242],
                        [-0.3723237, 51.433909],
                        [-0.3717586, 51.4338132],
                        [-0.3709871, 51.4335189],
                        [-0.3695862, 51.4325892],
                        [-0.3692724, 51.4324399],
                        [-0.3690587, 51.4323879],
                        [-0.3680763, 51.4322582],
                        [-0.3671667, 51.4322709],
                        [-0.3665762, 51.43233],
                        [-0.3656671, 51.4325359],
                        [-0.3652078, 51.4325973],
                        [-0.3641288, 51.4324235],
                        [-0.3637859, 51.4323263],
                        [-0.363419, 51.4321276],
                        [-0.3624971, 51.4314384],
                        [-0.3622838, 51.4312472],
                        [-0.3616857, 51.4304901],
                        [-0.3605349, 51.4293396],
                        [-0.3601302, 51.428801],
                        [-0.3596642, 51.4278239],
                        [-0.3592708, 51.4266586],
                        [-0.3590626, 51.42633],
                        [-0.3585083, 51.4257142],
                        [-0.3582671, 51.4253582],
                        [-0.3572116, 51.4230638],
                        [-0.3566492, 51.4221506],
                        [-0.3563341, 51.4209194],
                        [-0.3555584, 51.4195417],
                        [-0.3550639, 51.4185143],
                        [-0.3546313, 51.4168703],
                        [-0.3541588, 51.4156664],
                        [-0.3537907, 51.4141176],
                        [-0.3533505, 51.4130215],
                        [-0.3530987, 51.410416],
                        [-0.3525196, 51.4087062],
                        [-0.3524518, 51.4076194],
                        [-0.3523206, 51.4069922],
                        [-0.3521733, 51.4065829],
                        [-0.3518109, 51.4059029],
                        [-0.3517487, 51.4055619],
                        [-0.351805, 51.4049126],
                        [-0.3523425, 51.4036451],
                        [-0.3524774, 51.4031657],
                        [-0.3525478, 51.4002519],
                        [-0.3526638, 51.3995863],
                        [-0.3530273, 51.3987734],
                        [-0.353093, 51.3985004],
                        [-0.3531627, 51.3967226],
                        [-0.353288, 51.3953173],
                        [-0.3535, 51.3948846],
                        [-0.3542954, 51.3939995],
                        [-0.3554321, 51.3924378],
                        [-0.3563081, 51.3906597],
                        [-0.356508, 51.3904436],
                        [-0.3573476, 51.3897706],
                        [-0.3579096, 51.3892142],
                        [-0.3587833, 51.3879966],
                        [-0.3599849, 51.3871094],
                        [-0.3604088, 51.3863545],
                        [-0.3606902, 51.3859985],
                        [-0.3612864, 51.3855619],
                        [-0.3618341, 51.3852853],
                        [-0.3623393, 51.3851083],
                        [-0.3639823, 51.384661],
                        [-0.3648968, 51.3845919],
                        [-0.365247, 51.3846045],
                        [-0.3655453, 51.3846616],
                        [-0.367578, 51.3854102],
                        [-0.3682164, 51.3858142],
                        [-0.3686908, 51.3862664],
                        [-0.3692426, 51.387191],
                        [-0.3699871, 51.3878154],
                        [-0.3702485, 51.3881282],
                        [-0.3705432, 51.3891489],
                        [-0.3711687, 51.389965],
                        [-0.3714876, 51.3908704],
                        [-0.3719893, 51.3914771],
                        [-0.3723812, 51.3918916],
                        [-0.3727571, 51.39209],
                        [-0.3730722, 51.3921264],
                        [-0.373448, 51.3921],
                        [-0.373774, 51.3919409],
                        [-0.3739248, 51.3917097],
                        [-0.3740467, 51.3913404],
                        [-0.374329, 51.3900278],
                        [-0.3744006, 51.3864467],
                        [-0.3748358, 51.3854413],
                        [-0.3749813, 51.3844998],
                        [-0.3754255, 51.3828171]
                    ],
                    [
                        [-0.3539234, 51.4242969],
                        [-0.3535781, 51.4240415],
                        [-0.3534042, 51.4236691],
                        [-0.3532979, 51.4229872],
                        [-0.3533928, 51.4227773],
                        [-0.3535766, 51.4225652],
                        [-0.3536756, 51.4225176],
                        [-0.3540061, 51.4225505],
                        [-0.3543546, 51.4228911],
                        [-0.3545398, 51.4232293],
                        [-0.354751, 51.4238395],
                        [-0.3546715, 51.4240757],
                        [-0.3543109, 51.4242793],
                        [-0.3539234, 51.4242969]
                    ],
                    [
                        [-0.3004248, 51.4253906],
                        [-0.3022129, 51.4253928],
                        [-0.3061122, 51.4255212],
                        [-0.3076901, 51.4257094],
                        [-0.3093647, 51.4259923],
                        [-0.3110352, 51.4261656],
                        [-0.3121346, 51.4263831],
                        [-0.3128043, 51.4264757],
                        [-0.3159762, 51.4267831],
                        [-0.3182636, 51.4268632],
                        [-0.3220965, 51.4272933],
                        [-0.3249953, 51.4277213],
                        [-0.3264228, 51.4279722],
                        [-0.3279094, 51.4281527],
                        [-0.3332651, 51.4289245],
                        [-0.3349643, 51.4292361],
                        [-0.3362944, 51.4294146],
                        [-0.337884, 51.4296982],
                        [-0.3393557, 51.4298896],
                        [-0.3408574, 51.4302102],
                        [-0.3422139, 51.4303805],
                        [-0.3436525, 51.430612],
                        [-0.3450457, 51.4307518],
                        [-0.3465291, 51.431073],
                        [-0.3479083, 51.4311835],
                        [-0.3493514, 51.4314128],
                        [-0.3508489, 51.4315482],
                        [-0.3523423, 51.4317468],
                        [-0.35309, 51.4317015],
                        [-0.3536796, 51.4315701],
                        [-0.3541097, 51.4313088],
                        [-0.3543091, 51.43108],
                        [-0.354433, 51.4307117],
                        [-0.3544895, 51.4292844],
                        [-0.3546988, 51.4276269],
                        [-0.3545499, 51.4264558],
                        [-0.3545838, 51.426343],
                        [-0.35477, 51.4261624],
                        [-0.3549502, 51.4260736],
                        [-0.3551188, 51.4260671],
                        [-0.3552613, 51.4260916],
                        [-0.3553393, 51.4261451],
                        [-0.3556329, 51.4265572],
                        [-0.3558804, 51.4272821],
                        [-0.3560478, 51.4275706],
                        [-0.3568424, 51.4282224],
                        [-0.3576166, 51.4292056],
                        [-0.3594645, 51.4310487],
                        [-0.3602823, 51.4320556],
                        [-0.3621365, 51.4336443],
                        [-0.362668, 51.4339517],
                        [-0.3637287, 51.4343472],
                        [-0.3653123, 51.4346114],
                        [-0.3693999, 51.4357885],
                        [-0.37086, 51.4360191],
                        [-0.3729265, 51.43675],
                        [-0.3748113, 51.4371793],
                        [-0.3752403, 51.4373518],
                        [-0.3762454, 51.4379383],
                        [-0.3775862, 51.4384596],
                        [-0.3789646, 51.4388579],
                        [-0.3808217, 51.4392998],
                        [-0.3820928, 51.4397688],
                        [-0.3835548, 51.4401972],
                        [-0.384934, 51.4407379],
                        [-0.3868915, 51.441239],
                        [-0.3883281, 51.441846],
                        [-0.3893126, 51.4423554],
                        [-0.3906325, 51.4425778],
                        [-0.3915768, 51.44308],
                        [-0.3922, 51.44334],
                        [-0.3928342, 51.4435427],
                        [-0.3941009, 51.4438736],
                        [-0.3951567, 51.4442611],
                        [-0.3974262, 51.4449716],
                        [-0.397961, 51.4451995],
                        [-0.3991293, 51.4458484],
                        [-0.3997988, 51.4460993],
                        [-0.4020569, 51.4467388],
                        [-0.4037568, 51.4471037],
                        [-0.4051759, 51.4472967],
                        [-0.4061654, 51.447538],
                        [-0.4068045, 51.4477369],
                        [-0.4079252, 51.4481723],
                        [-0.4091672, 51.4487297],
                        [-0.4097444, 51.4489391],
                        [-0.4106151, 51.4491842],
                        [-0.4136123, 51.4498898],
                        [-0.4148693, 51.4501508],
                        [-0.4164171, 51.4503568],
                        [-0.4177124, 51.4506441],
                        [-0.4203716, 51.4508958],
                        [-0.4221078, 51.4509698],
                        [-0.4264043, 51.4510562],
                        [-0.4297453, 51.4511857],
                        [-0.4335186, 51.45127],
                        [-0.4363287, 51.4513773],
                        [-0.4366574, 51.451415],
                        [-0.4370801, 51.4515329],
                        [-0.4378525, 51.4520288],
                        [-0.4382491, 51.4521024],
                        [-0.4410412, 51.4522694],
                        [-0.4446191, 51.4523388],
                        [-0.445293, 51.4523911],
                        [-0.4458265, 51.4525436],
                        [-0.4464982, 51.4530194],
                        [-0.4468599, 51.4531037],
                        [-0.4480155, 51.4532409],
                        [-0.4506097, 51.4533129],
                        [-0.4513465, 51.453405],
                        [-0.4518185, 51.4535727],
                        [-0.4525715, 51.4540049],
                        [-0.454512, 51.454395],
                        [-0.4557312, 51.4549669],
                        [-0.4576582, 51.4553761],
                        [-0.4587615, 51.4559083],
                        [-0.4593139, 51.4560339],
                        [-0.4606184, 51.4562135],
                        [-0.4611986, 51.4563372],
                        [-0.4616117, 51.4565147],
                        [-0.4621253, 51.4568775],
                        [-0.4631057, 51.4571454],
                        [-0.4638321, 51.4575101],
                        [-0.4640373, 51.4575547],
                        [-0.4657697, 51.4576417],
                        [-0.4674358, 51.4575916],
                        [-0.4676946, 51.4575431],
                        [-0.468646, 51.4572429],
                        [-0.4693326, 51.4571399],
                        [-0.4739381, 51.4570032],
                        [-0.4768186, 51.4568347],
                        [-0.4780383, 51.4566725],
                        [-0.4792334, 51.4565994],
                        [-0.4809348, 51.4562543],
                        [-0.4822385, 51.4561992],
                        [-0.483434, 51.4560946],
                        [-0.485429, 51.4561112],
                        [-0.4866938, 51.4563277],
                        [-0.4870445, 51.4563466],
                        [-0.4885542, 51.4562612],
                        [-0.4907335, 51.4562711],
                        [-0.4915772, 51.4563684],
                        [-0.4924741, 51.4565313],
                        [-0.4931691, 51.4567668],
                        [-0.4935185, 51.4569936],
                        [-0.4936183, 51.4571349],
                        [-0.4936276, 51.4572904],
                        [-0.493381, 51.4576767],
                        [-0.4932639, 51.4580126],
                        [-0.4933745, 51.4586566],
                        [-0.4933361, 51.458894],
                        [-0.4932505, 51.4590429],
                        [-0.4929929, 51.4592834],
                        [-0.4910024, 51.4605813],
                        [-0.4902376, 51.4612119],
                        [-0.4897199, 51.4614214],
                        [-0.489391, 51.4614802],
                        [-0.4888955, 51.4615042],
                        [-0.4825082, 51.4615188],
                        [-0.4823196, 51.4615374],
                        [-0.4820196, 51.4616403],
                        [-0.4815437, 51.4620098],
                        [-0.4812307, 51.4621595],
                        [-0.4807299, 51.4622699],
                        [-0.4800185, 51.4623078],
                        [-0.4648545, 51.4621888],
                        [-0.462111, 51.4622185],
                        [-0.4619397, 51.4622639],
                        [-0.4612393, 51.4627377],
                        [-0.4605666, 51.4630097],
                        [-0.4603188, 51.4632379],
                        [-0.4602514, 51.463475],
                        [-0.4601679, 51.4648379],
                        [-0.4600908, 51.4664181],
                        [-0.4601532, 51.4666791],
                        [-0.4604241, 51.4669171],
                        [-0.4610561, 51.4671978],
                        [-0.4614605, 51.4676159],
                        [-0.4618028, 51.4677425],
                        [-0.4623028, 51.4677915],
                        [-0.4651589, 51.4678499],
                        [-0.4733552, 51.4678396],
                        [-0.4761962, 51.4679322],
                        [-0.4789292, 51.4679281],
                        [-0.4810538, 51.4682138],
                        [-0.48151, 51.4683726],
                        [-0.4819985, 51.4686179],
                        [-0.4824649, 51.4686928],
                        [-0.4902204, 51.4687629],
                        [-0.4905086, 51.4688343],
                        [-0.4906717, 51.4689319],
                        [-0.49136, 51.4696162],
                        [-0.4915739, 51.4699865],
                        [-0.4916452, 51.4705399],
                        [-0.4914411, 51.4707223],
                        [-0.4910124, 51.4708856],
                        [-0.4907332, 51.4709523],
                        [-0.4901491, 51.4710107],
                        [-0.4865273, 51.4709968],
                        [-0.4844217, 51.4711074],
                        [-0.4789581, 51.4711307],
                        [-0.4770671, 51.4711993],
                        [-0.4744037, 51.4711226],
                        [-0.470756, 51.4711332],
                        [-0.4701808, 51.4712041],
                        [-0.4692245, 51.4714618],
                        [-0.4686345, 51.471499],
                        [-0.4677328, 51.4713312],
                        [-0.466566, 51.470944],
                        [-0.4657901, 51.4708302],
                        [-0.4642011, 51.4707275],
                        [-0.4631043, 51.4707474],
                        [-0.4617363, 51.4706207],
                        [-0.4601272, 51.4706015],
                        [-0.4585994, 51.4705068],
                        [-0.4562638, 51.47045],
                        [-0.4554006, 51.4703722],
                        [-0.4546832, 51.4702321],
                        [-0.4536333, 51.4698856],
                        [-0.4530874, 51.469763],
                        [-0.4504711, 51.4695439],
                        [-0.4493398, 51.4693667],
                        [-0.4488483, 51.4692558],
                        [-0.447888, 51.468933],
                        [-0.4470768, 51.4687598],
                        [-0.4459669, 51.4686107],
                        [-0.4444308, 51.468498],
                        [-0.4418086, 51.4681597],
                        [-0.4403738, 51.4679191],
                        [-0.437389, 51.4675547],
                        [-0.4344723, 51.4673651],
                        [-0.4332479, 51.4673175],
                        [-0.4314596, 51.4670641],
                        [-0.4292503, 51.4668715],
                        [-0.4248691, 51.4666619],
                        [-0.4224227, 51.4664286],
                        [-0.4203, 51.4662719],
                        [-0.4185615, 51.4661877],
                        [-0.4162069, 51.4661464],
                        [-0.4143291, 51.4662327],
                        [-0.4128921, 51.4664288],
                        [-0.4099688, 51.4666602],
                        [-0.4085512, 51.4668812],
                        [-0.4031146, 51.4683694],
                        [-0.4015027, 51.4689052],
                        [-0.399721, 51.4692565],
                        [-0.3969697, 51.4699869],
                        [-0.3955323, 51.4701704],
                        [-0.3937012, 51.4706216],
                        [-0.3914588, 51.4709103],
                        [-0.3908688, 51.4709375],
                        [-0.3884377, 51.470818],
                        [-0.3869009, 51.4704927],
                        [-0.3861131, 51.4702149],
                        [-0.3839811, 51.4692899],
                        [-0.3828299, 51.4686529],
                        [-0.3809965, 51.46778],
                        [-0.3805161, 51.4675006],
                        [-0.3797308, 51.4667971],
                        [-0.3787356, 51.4656313],
                        [-0.3782861, 51.4649062],
                        [-0.3782163, 51.4645903],
                        [-0.3783047, 51.4642703],
                        [-0.3784501, 51.4640282],
                        [-0.37963, 51.462831],
                        [-0.3817702, 51.4614947],
                        [-0.384244, 51.4596447],
                        [-0.3856231, 51.4589541],
                        [-0.3875202, 51.4578771],
                        [-0.3878764, 51.4576522],
                        [-0.3881223, 51.4574184],
                        [-0.3885567, 51.4567254],
                        [-0.3884912, 51.4559041],
                        [-0.3883573, 51.4555948],
                        [-0.3880416, 51.4551239],
                        [-0.3878105, 51.4548882],
                        [-0.3874777, 51.4546668],
                        [-0.385993, 51.4540123],
                        [-0.3851072, 51.4534441],
                        [-0.3847219, 51.4532449],
                        [-0.3834681, 51.4528773],
                        [-0.3825053, 51.452363],
                        [-0.380633, 51.4516187],
                        [-0.3792212, 51.4511402],
                        [-0.3778875, 51.4505878],
                        [-0.3761189, 51.4501029],
                        [-0.3732604, 51.4488592],
                        [-0.3715123, 51.4484504],
                        [-0.3704045, 51.4480269],
                        [-0.3689184, 51.4476238],
                        [-0.3678431, 51.4471676],
                        [-0.367525, 51.4471182],
                        [-0.3671482, 51.4471641],
                        [-0.3664486, 51.4474251],
                        [-0.3661429, 51.4475954],
                        [-0.3657859, 51.4478757],
                        [-0.3648705, 51.4488762],
                        [-0.3645797, 51.4490229],
                        [-0.3644423, 51.4490347],
                        [-0.3642204, 51.4489911],
                        [-0.3617802, 51.4475905],
                        [-0.3614933, 51.4473057],
                        [-0.3608692, 51.4464886],
                        [-0.3598686, 51.4457662],
                        [-0.3590178, 51.4449287],
                        [-0.3578861, 51.4444193],
                        [-0.3571844, 51.443959],
                        [-0.355598, 51.4433698],
                        [-0.3544811, 51.4427271],
                        [-0.3528107, 51.4421644],
                        [-0.3518474, 51.441647],
                        [-0.3500654, 51.4411729],
                        [-0.3489943, 51.440625],
                        [-0.3475243, 51.4402731],
                        [-0.3463328, 51.4396839],
                        [-0.3460533, 51.4396024],
                        [-0.3447924, 51.4393812],
                        [-0.343463, 51.4387589],
                        [-0.341936, 51.4384563],
                        [-0.3406138, 51.4378198],
                        [-0.3401859, 51.437707],
                        [-0.3392439, 51.4375441],
                        [-0.3388569, 51.4374131],
                        [-0.3377291, 51.4369022],
                        [-0.3362397, 51.4365862],
                        [-0.3347753, 51.4359316],
                        [-0.3333988, 51.4356399],
                        [-0.3323809, 51.4352587],
                        [-0.3318441, 51.4351114],
                        [-0.3286481, 51.4345358],
                        [-0.3280376, 51.4343276],
                        [-0.3274541, 51.4340256],
                        [-0.327082, 51.4338808],
                        [-0.3253934, 51.4334868],
                        [-0.3243037, 51.4330595],
                        [-0.3231137, 51.432748],
                        [-0.3218615, 51.4322877],
                        [-0.3206106, 51.4319549],
                        [-0.3192747, 51.4314416],
                        [-0.3160086, 51.430612],
                        [-0.3145999, 51.4300537],
                        [-0.3132967, 51.429726],
                        [-0.3121677, 51.4293192],
                        [-0.3106641, 51.4289598],
                        [-0.3089814, 51.4286566],
                        [-0.3080707, 51.428437],
                        [-0.3071717, 51.4281238],
                        [-0.3060126, 51.4278078],
                        [-0.3049295, 51.4273592],
                        [-0.3025119, 51.4269516],
                        [-0.3018521, 51.42676],
                        [-0.3008406, 51.42639],
                        [-0.2981495, 51.42602],
                        [-0.2971643, 51.425805],
                        [-0.2966247, 51.42555],
                        [-0.2963607, 51.4253306],
                        [-0.2961722, 51.4252395],
                        [-0.2958847, 51.4251608],
                        [-0.2953857, 51.4250997],
                        [-0.2949041, 51.4248604],
                        [-0.295218, 51.424852],
                        [-0.2955271, 51.4249272],
                        [-0.2958608, 51.4249538],
                        [-0.2970882, 51.42488],
                        [-0.297875, 51.4248867],
                        [-0.2991438, 51.4251262],
                        [-0.2997648, 51.4252891],
                        [-0.3004248, 51.4253906]
                    ]
                ],
                [
                    [
                        [-0.2819215, 51.4372553],
                        [-0.2819502, 51.4372164],
                        [-0.2818912, 51.4371907],
                        [-0.2817822, 51.4372472],
                        [-0.2819215, 51.4372553]
                    ]
                ],
                [
                    [
                        [-0.660693, 51.4709508],
                        [-0.6517938, 51.470922],
                        [-0.6388388, 51.4709903],
                        [-0.6369974, 51.4709167],
                        [-0.6350109, 51.4707988],
                        [-0.6328733, 51.4707509],
                        [-0.6100494, 51.4708726],
                        [-0.6064688, 51.4708021],
                        [-0.6024223, 51.4706714],
                        [-0.6011179, 51.4706989],
                        [-0.5980703, 51.4708821],
                        [-0.593817, 51.4709534],
                        [-0.5292783, 51.4712054],
                        [-0.5279529, 51.471243],
                        [-0.5268741, 51.4714124],
                        [-0.5263389, 51.4714485],
                        [-0.525083, 51.4714011],
                        [-0.5235844, 51.4712609],
                        [-0.5225157, 51.4712308],
                        [-0.5110303, 51.4712578],
                        [-0.5091734, 51.4713129],
                        [-0.5085976, 51.4713846],
                        [-0.5077462, 51.4715777],
                        [-0.5070808, 51.4716241],
                        [-0.5061934, 51.471621],
                        [-0.5044559, 51.4715297],
                        [-0.5022368, 51.4715714],
                        [-0.5005252, 51.4716575],
                        [-0.4993315, 51.4715577],
                        [-0.4986063, 51.4715568],
                        [-0.4969868, 51.4716997],
                        [-0.4962742, 51.4718505],
                        [-0.4958613, 51.4721143],
                        [-0.4947141, 51.4731265],
                        [-0.4938826, 51.4734886],
                        [-0.4936176, 51.4736619],
                        [-0.493002, 51.4744667],
                        [-0.4928581, 51.4750218],
                        [-0.4921272, 51.475512],
                        [-0.4918392, 51.4759138],
                        [-0.4917089, 51.4767448],
                        [-0.4917937, 51.4779288],
                        [-0.4919672, 51.4786614],
                        [-0.4922229, 51.4791479],
                        [-0.4923643, 51.4792749],
                        [-0.4928671, 51.4795123],
                        [-0.4931893, 51.4797156],
                        [-0.4936271, 51.4806142],
                        [-0.4944808, 51.4813318],
                        [-0.4954896, 51.4827134],
                        [-0.4959497, 51.4830849],
                        [-0.4962645, 51.4831982],
                        [-0.497046, 51.483329],
                        [-0.5001116, 51.483656],
                        [-0.5021265, 51.484215],
                        [-0.5032401, 51.4844108],
                        [-0.5046336, 51.4845908],
                        [-0.506713, 51.4850258],
                        [-0.5075371, 51.4851422],
                        [-0.5115706, 51.4853307],
                        [-0.5148077, 51.4853736],
                        [-0.518607, 51.4853721],
                        [-0.5191901, 51.4854484],
                        [-0.5201277, 51.4857422],
                        [-0.5207569, 51.4858451],
                        [-0.5228107, 51.4859886],
                        [-0.5245981, 51.4860296],
                        [-0.5261731, 51.4861122],
                        [-0.5315538, 51.4861983],
                        [-0.5417125, 51.4862431],
                        [-0.5427217, 51.4863308],
                        [-0.5446734, 51.4866251],
                        [-0.5458248, 51.4867366],
                        [-0.5490439, 51.4868087],
                        [-0.5516498, 51.4869831],
                        [-0.5533745, 51.4870608],
                        [-0.5566026, 51.4871186],
                        [-0.5631504, 51.4871032],
                        [-0.5664007, 51.4871334],
                        [-0.5695878, 51.4872346],
                        [-0.574847, 51.487501],
                        [-0.5819533, 51.4876822],
                        [-0.6076671, 51.4878078],
                        [-0.6235158, 51.4877638],
                        [-0.6415111, 51.4875464],
                        [-0.6540989, 51.4874924],
                        [-0.6610352, 51.4873856],
                        [-0.664664, 51.4872169],
                        [-0.6685111, 51.4869878],
                        [-0.676875, 51.4867465],
                        [-0.6789637, 51.4866568],
                        [-0.6941151, 51.4863483],
                        [-0.6961861, 51.4862627],
                        [-0.7059296, 51.4861282],
                        [-0.713273, 51.4859452],
                        [-0.717551, 51.4859043],
                        [-0.7290381, 51.4856435],
                        [-0.733367, 51.4854601],
                        [-0.7431895, 51.4852005],
                        [-0.7452754, 51.485114],
                        [-0.7565002, 51.4849218],
                        [-0.7593637, 51.4847579],
                        [-0.7621547, 51.4846961],
                        [-0.7647913, 51.4845587],
                        [-0.7679739, 51.4844575],
                        [-0.7736766, 51.4843653],
                        [-0.7793002, 51.4839237],
                        [-0.7811136, 51.4839163],
                        [-0.7852098, 51.4836048],
                        [-0.78816, 51.4835445],
                        [-0.7892864, 51.4834893],
                        [-0.7914351, 51.4833088],
                        [-0.7939305, 51.4831516],
                        [-0.7969073, 51.4827975],
                        [-0.7995299, 51.4827353],
                        [-0.8001389, 51.4826661],
                        [-0.8030393, 51.4820132],
                        [-0.8043014, 51.4818133],
                        [-0.8058653, 51.4816781],
                        [-0.8068154, 51.4815301],
                        [-0.8084211, 51.4810355],
                        [-0.8109645, 51.4804574],
                        [-0.8112426, 51.4803066],
                        [-0.8118976, 51.4797969],
                        [-0.8122778, 51.4795838],
                        [-0.8127947, 51.479374],
                        [-0.8135212, 51.4791701],
                        [-0.8138395, 51.4790036],
                        [-0.8141123, 51.478714],
                        [-0.8144973, 51.4780052],
                        [-0.8144505, 51.4774604],
                        [-0.8143006, 51.4772018],
                        [-0.814132, 51.4771159],
                        [-0.8130812, 51.4769385],
                        [-0.8126565, 51.4767816],
                        [-0.812463, 51.476641],
                        [-0.8121349, 51.4762098],
                        [-0.8118262, 51.4760518],
                        [-0.8113697, 51.4759317],
                        [-0.8101593, 51.4758633],
                        [-0.8086029, 51.4756953],
                        [-0.8081899, 51.4755135],
                        [-0.8077256, 51.4751188],
                        [-0.8073364, 51.4749053],
                        [-0.8060919, 51.474553],
                        [-0.804712, 51.4740976],
                        [-0.8017867, 51.473293],
                        [-0.8009985, 51.4731742],
                        [-0.7985761, 51.4729954],
                        [-0.7970032, 51.4727189],
                        [-0.7964244, 51.4726528],
                        [-0.7942884, 51.4724745],
                        [-0.7926961, 51.4724474],
                        [-0.7911744, 51.4722996],
                        [-0.7882417, 51.4721052],
                        [-0.7841658, 51.4720126],
                        [-0.7815472, 51.4718893],
                        [-0.7753601, 51.4716782],
                        [-0.7664557, 51.4714925],
                        [-0.7640648, 51.4715105],
                        [-0.7582913, 51.4714732],
                        [-0.7568096, 51.4715178],
                        [-0.7539396, 51.4716929],
                        [-0.7478654, 51.4717803],
                        [-0.7363192, 51.4717019],
                        [-0.7308893, 51.4716907],
                        [-0.7265108, 51.4717229],
                        [-0.7177279, 51.471558],
                        [-0.7117454, 51.4713102],
                        [-0.7078675, 51.4712129],
                        [-0.6965399, 51.4710619],
                        [-0.6924802, 51.4708702],
                        [-0.6889468, 51.4707956],
                        [-0.6760223, 51.4708385],
                        [-0.6675664, 51.4707595],
                        [-0.660693, 51.4709508]
                    ],
                    [
                        [-0.6673938, 51.4716063],
                        [-0.6761257, 51.4716097],
                        [-0.6847942, 51.4714494],
                        [-0.6877623, 51.4714959],
                        [-0.6907194, 51.4717167],
                        [-0.6951414, 51.4718775],
                        [-0.6994111, 51.4719749],
                        [-0.7039538, 51.4721776],
                        [-0.7090731, 51.4723552],
                        [-0.7147706, 51.4724795],
                        [-0.7180744, 51.4726674],
                        [-0.7237321, 51.4728473],
                        [-0.7354788, 51.4729402],
                        [-0.7377452, 51.4730297],
                        [-0.7396384, 51.4731481],
                        [-0.7458049, 51.4733833],
                        [-0.7489931, 51.4736899],
                        [-0.7508095, 51.4739107],
                        [-0.7513299, 51.4740115],
                        [-0.7525856, 51.4743934],
                        [-0.7541072, 51.4746859],
                        [-0.7555236, 51.4750078],
                        [-0.7581558, 51.4754213],
                        [-0.759599, 51.4758806],
                        [-0.7604297, 51.4762671],
                        [-0.7607708, 51.4764962],
                        [-0.7611298, 51.4768201],
                        [-0.761362, 51.477149],
                        [-0.7614006, 51.4773925],
                        [-0.7613032, 51.4775632],
                        [-0.7607202, 51.4780615],
                        [-0.7603334, 51.478301],
                        [-0.7599291, 51.4784818],
                        [-0.7559001, 51.4799299],
                        [-0.7540965, 51.4803614],
                        [-0.7526911, 51.4807418],
                        [-0.7510595, 51.4809887],
                        [-0.7486274, 51.4815471],
                        [-0.7467033, 51.4818808],
                        [-0.7456182, 51.4820281],
                        [-0.7435827, 51.4822146],
                        [-0.7420276, 51.4824603],
                        [-0.7391206, 51.4827004],
                        [-0.732407, 51.4831193],
                        [-0.725009, 51.4834941],
                        [-0.7222164, 51.4835816],
                        [-0.7190108, 51.4837337],
                        [-0.713613, 51.4838889],
                        [-0.7106747, 51.4839305],
                        [-0.707793, 51.4841205],
                        [-0.7046228, 51.4842082],
                        [-0.6974488, 51.4845257],
                        [-0.6855172, 51.4847301],
                        [-0.6832335, 51.4848022],
                        [-0.6803741, 51.484907],
                        [-0.6772, 51.4850757],
                        [-0.6743964, 51.4851512],
                        [-0.6728827, 51.4852393],
                        [-0.6638788, 51.4855764],
                        [-0.6552763, 51.4858575],
                        [-0.6467649, 51.4860272],
                        [-0.6414459, 51.4863246],
                        [-0.6396693, 51.4864661],
                        [-0.6313646, 51.4865892],
                        [-0.6249993, 51.4866427],
                        [-0.609859, 51.4864981],
                        [-0.596781, 51.4864835],
                        [-0.5925452, 51.4863464],
                        [-0.5877821, 51.4863763],
                        [-0.5849301, 51.4863139],
                        [-0.5818137, 51.4863326],
                        [-0.5734011, 51.486185],
                        [-0.5605806, 51.4860678],
                        [-0.5557704, 51.4859613],
                        [-0.5492007, 51.4855692],
                        [-0.5450432, 51.4855373],
                        [-0.5430761, 51.4854669],
                        [-0.5376482, 51.485447],
                        [-0.5357764, 51.4855003],
                        [-0.5330998, 51.485458],
                        [-0.528683, 51.4855006],
                        [-0.5262476, 51.4853842],
                        [-0.5248085, 51.4851469],
                        [-0.523143, 51.4850606],
                        [-0.5217868, 51.4848368],
                        [-0.5212785, 51.4847931],
                        [-0.5186492, 51.4846777],
                        [-0.5119555, 51.4846555],
                        [-0.5101794, 51.4846008],
                        [-0.5070799, 51.4843703],
                        [-0.5066096, 51.4842909],
                        [-0.5055536, 51.4840307],
                        [-0.5030121, 51.4838236],
                        [-0.5010998, 51.4834192],
                        [-0.4973854, 51.4831633],
                        [-0.4963977, 51.4830272],
                        [-0.4960079, 51.4829171],
                        [-0.4957618, 51.4827651],
                        [-0.4955376, 51.4825392],
                        [-0.4946159, 51.4812079],
                        [-0.4938246, 51.480463],
                        [-0.493441, 51.4797082],
                        [-0.493155, 51.4794602],
                        [-0.4925875, 51.4791429],
                        [-0.4923215, 51.4788758],
                        [-0.4921194, 51.4783884],
                        [-0.4919748, 51.4776361],
                        [-0.4919932, 51.4767646],
                        [-0.4921351, 51.4763408],
                        [-0.4923523, 51.4759987],
                        [-0.4925499, 51.4757948],
                        [-0.4930481, 51.475453],
                        [-0.4932779, 51.4752365],
                        [-0.4935715, 51.4745438],
                        [-0.4940474, 51.4738831],
                        [-0.4950268, 51.4730867],
                        [-0.4958022, 51.4723451],
                        [-0.4962459, 51.4720586],
                        [-0.4966309, 51.4719455],
                        [-0.4969092, 51.4719059],
                        [-0.4976442, 51.4718325],
                        [-0.4987736, 51.4717775],
                        [-0.5005364, 51.4718542],
                        [-0.5046118, 51.4717685],
                        [-0.5073215, 51.4717975],
                        [-0.5077154, 51.4717521],
                        [-0.5088533, 51.4714687],
                        [-0.5096396, 51.4713897],
                        [-0.5111204, 51.4713603],
                        [-0.5222266, 51.4713366],
                        [-0.5235053, 51.4713628],
                        [-0.5250288, 51.4714847],
                        [-0.526256, 51.4715298],
                        [-0.5268346, 51.4715008],
                        [-0.528046, 51.4713467],
                        [-0.5291592, 51.4713225],
                        [-0.5335257, 51.471329],
                        [-0.572399, 51.4711431],
                        [-0.5929896, 51.4710943],
                        [-0.5977589, 51.4710241],
                        [-0.6010189, 51.4708173],
                        [-0.6026951, 51.4707836],
                        [-0.6063877, 51.4709238],
                        [-0.6101252, 51.4709992],
                        [-0.6287458, 51.4708991],
                        [-0.6329219, 51.4709199],
                        [-0.6350033, 51.4710249],
                        [-0.6364949, 51.4712033],
                        [-0.6374773, 51.4713765],
                        [-0.6391684, 51.4714684],
                        [-0.643131, 51.4714399],
                        [-0.6502507, 51.4712713],
                        [-0.6558994, 51.4712368],
                        [-0.6591371, 51.4712751],
                        [-0.6621586, 51.4715795],
                        [-0.663334, 51.4716638],
                        [-0.6673938, 51.4716063]
                    ]
                ],
                [
                    [
                        [-0.374092, 51.4758487],
                        [-0.3732515, 51.4746183],
                        [-0.3728489, 51.4741801],
                        [-0.3717883, 51.4733778],
                        [-0.3712143, 51.4729547],
                        [-0.3709958, 51.4728864],
                        [-0.3706874, 51.4728721],
                        [-0.3676702, 51.4732985],
                        [-0.3665819, 51.4734182],
                        [-0.3653064, 51.4736365],
                        [-0.3637265, 51.4737412],
                        [-0.3594994, 51.4744875],
                        [-0.3575196, 51.4749415],
                        [-0.3557012, 51.4754381],
                        [-0.3547625, 51.4758729],
                        [-0.3534962, 51.4763015],
                        [-0.352426, 51.4768196],
                        [-0.3512888, 51.47722],
                        [-0.3504327, 51.4777583],
                        [-0.3495916, 51.4781688],
                        [-0.3488899, 51.4786455],
                        [-0.3480728, 51.4790362],
                        [-0.3475577, 51.4794129],
                        [-0.3465916, 51.479913],
                        [-0.3459282, 51.4803827],
                        [-0.3425467, 51.4821013],
                        [-0.3412318, 51.482845],
                        [-0.340672, 51.4830816],
                        [-0.339762, 51.4833853],
                        [-0.3392785, 51.483616],
                        [-0.3368726, 51.4851097],
                        [-0.3357231, 51.48592],
                        [-0.3340925, 51.4867541],
                        [-0.3329382, 51.4875438],
                        [-0.3309991, 51.4885701],
                        [-0.3299912, 51.4892981],
                        [-0.3286357, 51.4900434],
                        [-0.3277035, 51.4907822],
                        [-0.3263074, 51.4914948],
                        [-0.3252563, 51.4922067],
                        [-0.3239016, 51.4929237],
                        [-0.3224531, 51.4938157],
                        [-0.3210312, 51.4944826],
                        [-0.3181945, 51.4960787],
                        [-0.3173143, 51.4966658],
                        [-0.3152978, 51.4978846],
                        [-0.3131832, 51.4992729],
                        [-0.3121722, 51.4998388],
                        [-0.3094606, 51.5016471],
                        [-0.3074787, 51.5030538],
                        [-0.3071631, 51.5033656],
                        [-0.306822, 51.5039334],
                        [-0.3060369, 51.504803],
                        [-0.3049914, 51.505397],
                        [-0.3039483, 51.5062612],
                        [-0.3034397, 51.5067942],
                        [-0.3026785, 51.5072465],
                        [-0.3015754, 51.5085768],
                        [-0.3010709, 51.5091092],
                        [-0.3003406, 51.5097616],
                        [-0.298968, 51.5107853],
                        [-0.2986237, 51.5111151],
                        [-0.2984419, 51.5114397],
                        [-0.2983369, 51.5119121],
                        [-0.2983324, 51.5130273],
                        [-0.2980001, 51.5136499],
                        [-0.2977053, 51.5140159],
                        [-0.2967701, 51.5147099],
                        [-0.2959166, 51.5155658],
                        [-0.2956837, 51.5159152],
                        [-0.2954333, 51.5166516],
                        [-0.2952989, 51.5173614],
                        [-0.2953389, 51.5177975],
                        [-0.295496, 51.5180002],
                        [-0.29577, 51.518093],
                        [-0.2961697, 51.518048],
                        [-0.2965822, 51.5178798],
                        [-0.2971361, 51.5175593],
                        [-0.2973803, 51.5174956],
                        [-0.2976463, 51.5175076],
                        [-0.2983866, 51.5177262],
                        [-0.2990553, 51.517979],
                        [-0.2994523, 51.5181807],
                        [-0.2998492, 51.5184625],
                        [-0.3001338, 51.5184838],
                        [-0.3004952, 51.5183582],
                        [-0.3012112, 51.5180013],
                        [-0.3019369, 51.5177438],
                        [-0.303397, 51.5173591],
                        [-0.3046513, 51.5169457],
                        [-0.3059418, 51.5167467],
                        [-0.3084888, 51.5154555],
                        [-0.3090881, 51.5152789],
                        [-0.3099305, 51.5151504],
                        [-0.3103194, 51.5150554],
                        [-0.3118551, 51.5142816],
                        [-0.3127816, 51.5137379],
                        [-0.313838, 51.5132693],
                        [-0.3145153, 51.5130145],
                        [-0.3160038, 51.512592],
                        [-0.3169264, 51.5121535],
                        [-0.3179176, 51.511767],
                        [-0.3187745, 51.5112557],
                        [-0.3191162, 51.5111274],
                        [-0.3203465, 51.5108066],
                        [-0.32221, 51.5099972],
                        [-0.3231733, 51.5094637],
                        [-0.3235078, 51.5093438],
                        [-0.3244255, 51.5091082],
                        [-0.3254726, 51.5086674],
                        [-0.3266261, 51.5082705],
                        [-0.3275649, 51.5077412],
                        [-0.3283774, 51.50734],
                        [-0.3290311, 51.5069224],
                        [-0.329325, 51.506793],
                        [-0.3297226, 51.5066909],
                        [-0.3308167, 51.5065164],
                        [-0.3318952, 51.5059583],
                        [-0.3327153, 51.5055916],
                        [-0.3337112, 51.5050404],
                        [-0.3340986, 51.5049139],
                        [-0.3348453, 51.5047845],
                        [-0.3351928, 51.5046784],
                        [-0.3355962, 51.5044772],
                        [-0.3363101, 51.5040159],
                        [-0.3383631, 51.5030561],
                        [-0.3410439, 51.5019803],
                        [-0.3421881, 51.5011909],
                        [-0.3433059, 51.5006238],
                        [-0.3440373, 51.5001671],
                        [-0.3457229, 51.4993682],
                        [-0.3468177, 51.4989021],
                        [-0.3484546, 51.4979501],
                        [-0.3499946, 51.4972918],
                        [-0.3506303, 51.4969068],
                        [-0.3517052, 51.4961224],
                        [-0.3531531, 51.4954553],
                        [-0.3546857, 51.4946025],
                        [-0.3559049, 51.4938197],
                        [-0.3580391, 51.4926892],
                        [-0.3597546, 51.4917113],
                        [-0.3609275, 51.4911146],
                        [-0.3619639, 51.490429],
                        [-0.3636887, 51.4894601],
                        [-0.3645717, 51.4888834],
                        [-0.3655606, 51.4883741],
                        [-0.3666854, 51.4877249],
                        [-0.3677274, 51.4870374],
                        [-0.3686273, 51.4866016],
                        [-0.3699163, 51.4858913],
                        [-0.3714188, 51.484849],
                        [-0.3729826, 51.4835922],
                        [-0.3738204, 51.4826236],
                        [-0.3744832, 51.4819866],
                        [-0.3749911, 51.4812218],
                        [-0.3751502, 51.4808415],
                        [-0.3751617, 51.4805018],
                        [-0.3750758, 51.4798715],
                        [-0.3750978, 51.4787486],
                        [-0.3749814, 51.4779923],
                        [-0.3747583, 51.4772952],
                        [-0.3744414, 51.4767274],
                        [-0.374092, 51.4758487]
                    ],
                    [
                        [-0.3690259, 51.4749258],
                        [-0.3697974, 51.475349],
                        [-0.3708988, 51.4757918],
                        [-0.3713226, 51.476069],
                        [-0.3723708, 51.4771011],
                        [-0.3731145, 51.4780795],
                        [-0.3736199, 51.479686],
                        [-0.3735564, 51.4800564],
                        [-0.3731616, 51.4805886],
                        [-0.3727541, 51.4814836],
                        [-0.3719211, 51.4824566],
                        [-0.3706787, 51.4834522],
                        [-0.369847, 51.4840266],
                        [-0.3693547, 51.4844369],
                        [-0.3679189, 51.4853502],
                        [-0.3664665, 51.4860057],
                        [-0.3646253, 51.4870779],
                        [-0.3631924, 51.4877597],
                        [-0.3623199, 51.4883301],
                        [-0.360063, 51.4896272],
                        [-0.3590918, 51.4902719],
                        [-0.3573555, 51.4910905],
                        [-0.3560742, 51.4920714],
                        [-0.3556868, 51.4922777],
                        [-0.3546106, 51.4927378],
                        [-0.3536087, 51.493452],
                        [-0.3530545, 51.4937944],
                        [-0.3523118, 51.4941509],
                        [-0.351106, 51.4946342],
                        [-0.3499654, 51.4952207],
                        [-0.349411, 51.4955495],
                        [-0.3482412, 51.4964102],
                        [-0.3467954, 51.4970679],
                        [-0.3455429, 51.4978594],
                        [-0.3444465, 51.4982825],
                        [-0.3440489, 51.4984765],
                        [-0.3418583, 51.4997224],
                        [-0.3393586, 51.5009603],
                        [-0.3382987, 51.5016339],
                        [-0.3371172, 51.502246],
                        [-0.3363636, 51.5025642],
                        [-0.3350663, 51.5029658],
                        [-0.3342023, 51.5033305],
                        [-0.3333937, 51.5037103],
                        [-0.3324703, 51.5042667],
                        [-0.3321333, 51.5044193],
                        [-0.3308706, 51.5047498],
                        [-0.329393, 51.5054452],
                        [-0.3272506, 51.5061828],
                        [-0.3255433, 51.5070407],
                        [-0.3250434, 51.5071996],
                        [-0.3241431, 51.5073934],
                        [-0.3235667, 51.5075614],
                        [-0.3226481, 51.507968],
                        [-0.3217439, 51.5083044],
                        [-0.3175125, 51.5096735],
                        [-0.3156643, 51.5105181],
                        [-0.3151912, 51.5106989],
                        [-0.3135396, 51.5111137],
                        [-0.3121134, 51.5116747],
                        [-0.3110253, 51.5118899],
                        [-0.3104423, 51.5120442],
                        [-0.3093183, 51.5125592],
                        [-0.3089574, 51.5126785],
                        [-0.3086275, 51.5127376],
                        [-0.307507, 51.5128376],
                        [-0.3071638, 51.5129229],
                        [-0.3064088, 51.5132341],
                        [-0.3060039, 51.5133381],
                        [-0.3054628, 51.5133147],
                        [-0.3046682, 51.513162],
                        [-0.3039721, 51.5131011],
                        [-0.3036956, 51.5130208],
                        [-0.3036523, 51.5128081],
                        [-0.3039211, 51.5119568],
                        [-0.3042551, 51.5112889],
                        [-0.3042239, 51.5110774],
                        [-0.3040056, 51.5106523],
                        [-0.3040109, 51.5105317],
                        [-0.3044479, 51.5096607],
                        [-0.304625, 51.5094117],
                        [-0.3051105, 51.5089429],
                        [-0.3058736, 51.5083116],
                        [-0.3064259, 51.5077353],
                        [-0.3070751, 51.5072183],
                        [-0.3085017, 51.5057566],
                        [-0.3106898, 51.5043054],
                        [-0.3113065, 51.5037858],
                        [-0.3121492, 51.5031968],
                        [-0.3127636, 51.5026676],
                        [-0.3140318, 51.5018778],
                        [-0.3149843, 51.5013569],
                        [-0.3158426, 51.5007451],
                        [-0.3220371, 51.4967525],
                        [-0.3242143, 51.4954201],
                        [-0.3255396, 51.4946965],
                        [-0.3267165, 51.4938489],
                        [-0.3284793, 51.4929314],
                        [-0.3297958, 51.491924],
                        [-0.3302942, 51.4916548],
                        [-0.3314198, 51.4911684],
                        [-0.3321239, 51.4907035],
                        [-0.3330886, 51.4901691],
                        [-0.3350683, 51.4889436],
                        [-0.3358439, 51.4885431],
                        [-0.3365717, 51.4880579],
                        [-0.3374976, 51.4875304],
                        [-0.3380837, 51.4869845],
                        [-0.3385953, 51.4866362],
                        [-0.3393989, 51.4862266],
                        [-0.3402396, 51.4857209],
                        [-0.3413108, 51.4853214],
                        [-0.3418159, 51.4850824],
                        [-0.344027, 51.4837223],
                        [-0.344777, 51.4833312],
                        [-0.3456891, 51.4827859],
                        [-0.3465076, 51.4823938],
                        [-0.3484733, 51.4811395],
                        [-0.3492855, 51.4806749],
                        [-0.3500928, 51.4801122],
                        [-0.3523112, 51.4790378],
                        [-0.3530443, 51.4785937],
                        [-0.3538163, 51.4781893],
                        [-0.3545485, 51.477722],
                        [-0.3549422, 51.4775394],
                        [-0.3563726, 51.4771018],
                        [-0.357147, 51.476687],
                        [-0.3580143, 51.4763298],
                        [-0.3589745, 51.4758618],
                        [-0.3607594, 51.4752369],
                        [-0.3623764, 51.4748146],
                        [-0.363673, 51.4746234],
                        [-0.3651338, 51.4744802],
                        [-0.3664099, 51.4744924],
                        [-0.3679258, 51.4746319],
                        [-0.3685499, 51.4747559],
                        [-0.3690259, 51.4749258]
                    ]
                ]
            ]
        }
    }]
}