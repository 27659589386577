export const d200_poly = {
    "type": "FeatureCollection",
    "name": "Decrease_200_Poly",
    "features": [{
        "type": "Feature",
        "properties": {
            "NAME": "-329",
            "LAYER": "Contour Line, Intermediate",
            "ELEVATION": -329,
            "CLOSED_CON": "YES"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [-0.4430538, 51.453533],
                        [-0.4379691, 51.4533004],
                        [-0.4374351, 51.4531593],
                        [-0.4364567, 51.4526762],
                        [-0.4360131, 51.452617],
                        [-0.4308487, 51.4524777],
                        [-0.4223786, 51.4523864],
                        [-0.4190221, 51.4524948],
                        [-0.4161763, 51.4524235],
                        [-0.4134172, 51.4524339],
                        [-0.4108424, 51.4525475],
                        [-0.4094028, 51.4526731],
                        [-0.4087085, 51.4528023],
                        [-0.4079425, 51.453056],
                        [-0.4062304, 51.4540309],
                        [-0.405194, 51.454724],
                        [-0.4040479, 51.4551967],
                        [-0.4031685, 51.4556883],
                        [-0.4021215, 51.456184],
                        [-0.4017124, 51.4564831],
                        [-0.4012137, 51.4569561],
                        [-0.4010363, 51.4574036],
                        [-0.4010185, 51.4577506],
                        [-0.4011399, 51.457933],
                        [-0.4013623, 51.4581174],
                        [-0.4027418, 51.4589574],
                        [-0.4044755, 51.4596782],
                        [-0.405526, 51.4602685],
                        [-0.4066812, 51.4608204],
                        [-0.4090155, 51.4617232],
                        [-0.4104484, 51.4621913],
                        [-0.4139069, 51.463166],
                        [-0.4147588, 51.4633537],
                        [-0.4176731, 51.4638886],
                        [-0.4201581, 51.4642906],
                        [-0.421666, 51.4644169],
                        [-0.4259402, 51.4645405],
                        [-0.4344528, 51.4646027],
                        [-0.4373271, 51.4646442],
                        [-0.4380153, 51.4646852],
                        [-0.4387903, 51.4648201],
                        [-0.4396766, 51.4652613],
                        [-0.4403015, 51.4654124],
                        [-0.4413595, 51.4654609],
                        [-0.4469447, 51.4655195],
                        [-0.4474718, 51.46555],
                        [-0.4478579, 51.4656356],
                        [-0.4485464, 51.46588],
                        [-0.4496464, 51.4661999],
                        [-0.450307, 51.4663606],
                        [-0.453378, 51.4665184],
                        [-0.4554971, 51.4670235],
                        [-0.4561298, 51.4671077],
                        [-0.4565603, 51.467065],
                        [-0.4571732, 51.4669365],
                        [-0.45754, 51.4668077],
                        [-0.4577969, 51.4666243],
                        [-0.4578675, 51.4664103],
                        [-0.4580047, 51.4639734],
                        [-0.4581375, 51.4633157],
                        [-0.4582506, 51.4630689],
                        [-0.4584846, 51.4628243],
                        [-0.4593422, 51.4622832],
                        [-0.4598605, 51.4618832],
                        [-0.4602221, 51.4616966],
                        [-0.460712, 51.4615542],
                        [-0.4612756, 51.4614641],
                        [-0.4620505, 51.461401],
                        [-0.4650553, 51.4613685],
                        [-0.473588, 51.4610875],
                        [-0.47985, 51.4610011],
                        [-0.4806115, 51.4609488],
                        [-0.4816457, 51.4606879],
                        [-0.482191, 51.4605961],
                        [-0.4836762, 51.4605561],
                        [-0.4850394, 51.4604638],
                        [-0.4890031, 51.4602916],
                        [-0.489421, 51.4602157],
                        [-0.4898299, 51.4600641],
                        [-0.4910916, 51.4594252],
                        [-0.4914012, 51.4592227],
                        [-0.4916193, 51.458985],
                        [-0.4915309, 51.4587685],
                        [-0.4911876, 51.4583981],
                        [-0.4909067, 51.4582093],
                        [-0.4905763, 51.4581132],
                        [-0.489453, 51.4580882],
                        [-0.4868156, 51.4581372],
                        [-0.485609, 51.458064],
                        [-0.4851755, 51.4579347],
                        [-0.4845066, 51.4575573],
                        [-0.4842661, 51.4574898],
                        [-0.4839201, 51.4574598],
                        [-0.4833855, 51.4575588],
                        [-0.482426, 51.4580212],
                        [-0.4819171, 51.4581222],
                        [-0.4779654, 51.458276],
                        [-0.4736903, 51.4583741],
                        [-0.4695054, 51.4583885],
                        [-0.4689338, 51.4584564],
                        [-0.4685935, 51.4585381],
                        [-0.4675031, 51.4589327],
                        [-0.466923, 51.4590826],
                        [-0.4664295, 51.4591468],
                        [-0.4661182, 51.4591219],
                        [-0.4653907, 51.4589651],
                        [-0.4645429, 51.4586445],
                        [-0.4638716, 51.4584535],
                        [-0.4623448, 51.4582573],
                        [-0.4618469, 51.4581515],
                        [-0.4613848, 51.4579714],
                        [-0.4607068, 51.4575982],
                        [-0.45988, 51.4573271],
                        [-0.459397, 51.4569634],
                        [-0.4590682, 51.4568185],
                        [-0.4576675, 51.4566801],
                        [-0.4570823, 51.4565748],
                        [-0.4566085, 51.4564216],
                        [-0.4558513, 51.4560976],
                        [-0.4541141, 51.4557168],
                        [-0.4525482, 51.4551271],
                        [-0.4512412, 51.4548101],
                        [-0.4506594, 51.4546285],
                        [-0.4482113, 51.4543988],
                        [-0.447688, 51.454285],
                        [-0.4465995, 51.453944],
                        [-0.4455277, 51.4538218],
                        [-0.4445896, 51.4536504],
                        [-0.4430538, 51.453533]
                    ],
                    [
                        [-0.4321945, 51.4591451],
                        [-0.4318564, 51.4590387],
                        [-0.4316228, 51.4589056],
                        [-0.4317598, 51.4586833],
                        [-0.4320978, 51.4584756],
                        [-0.4324474, 51.4583423],
                        [-0.4333837, 51.4580834],
                        [-0.4360074, 51.4577807],
                        [-0.4373103, 51.4575182],
                        [-0.4384978, 51.4576191],
                        [-0.4416407, 51.4576958],
                        [-0.4419998, 51.4577965],
                        [-0.4430566, 51.4582769],
                        [-0.4434891, 51.4584201],
                        [-0.444508, 51.4584718],
                        [-0.4453345, 51.4585693],
                        [-0.4490732, 51.4586721],
                        [-0.4492447, 51.4587398],
                        [-0.4494141, 51.4589993],
                        [-0.4495575, 51.4591115],
                        [-0.4500075, 51.4592889],
                        [-0.4510898, 51.4595151],
                        [-0.4531493, 51.4596187],
                        [-0.4532617, 51.4596701],
                        [-0.4533732, 51.4598304],
                        [-0.4536279, 51.4603257],
                        [-0.453644, 51.4605418],
                        [-0.4534708, 51.4607614],
                        [-0.4532046, 51.4609469],
                        [-0.4530546, 51.4610015],
                        [-0.451922, 51.4611359],
                        [-0.4509974, 51.4611375],
                        [-0.4506802, 51.4610938],
                        [-0.4504342, 51.4610043],
                        [-0.450012, 51.4606007],
                        [-0.4498122, 51.460499],
                        [-0.4493028, 51.4603609],
                        [-0.4489788, 51.4603169],
                        [-0.4474083, 51.4602111],
                        [-0.4421263, 51.4601665],
                        [-0.4407968, 51.459943],
                        [-0.4399493, 51.4595893],
                        [-0.4391239, 51.4594376],
                        [-0.4336622, 51.4592742],
                        [-0.4321945, 51.4591451]
                    ]
                ],
                [
                    [
                        [-0.4820398, 51.4703651],
                        [-0.483482, 51.4700876],
                        [-0.4839487, 51.4699183],
                        [-0.4839997, 51.4697125],
                        [-0.4831331, 51.469621],
                        [-0.4813777, 51.469546],
                        [-0.4810574, 51.469502],
                        [-0.480455, 51.4693358],
                        [-0.4800953, 51.4693153],
                        [-0.4797705, 51.4694174],
                        [-0.4793825, 51.4696723],
                        [-0.4792419, 51.4699685],
                        [-0.4792186, 51.4702784],
                        [-0.4793341, 51.4703377],
                        [-0.4804382, 51.4704498],
                        [-0.4820398, 51.4703651]
                    ]
                ],
                [
                    [
                        [-0.4743373, 51.4703473],
                        [-0.4748927, 51.4703459],
                        [-0.4762656, 51.4704326],
                        [-0.4784832, 51.4703154],
                        [-0.4783005, 51.4701749],
                        [-0.477984, 51.4700601],
                        [-0.4773976, 51.4699623],
                        [-0.4767225, 51.4699053],
                        [-0.4760048, 51.4698942],
                        [-0.474643, 51.4700057],
                        [-0.4720815, 51.4697754],
                        [-0.4704599, 51.4697549],
                        [-0.468929, 51.4696531],
                        [-0.4676764, 51.4696217],
                        [-0.466582, 51.4696194],
                        [-0.466096, 51.4696758],
                        [-0.4658777, 51.4699171],
                        [-0.4658024, 51.47012],
                        [-0.4659902, 51.4703232],
                        [-0.4661496, 51.4703866],
                        [-0.4673166, 51.4704893],
                        [-0.4687968, 51.4706852],
                        [-0.4703791, 51.4705078],
                        [-0.4725755, 51.4704875],
                        [-0.4743373, 51.4703473]
                    ]
                ],
                [
                    [
                        [-0.6236909, 51.471941],
                        [-0.6178555, 51.4719866],
                        [-0.6100193, 51.4719901],
                        [-0.6052766, 51.4718925],
                        [-0.602748, 51.4717992],
                        [-0.5973638, 51.471931],
                        [-0.5813121, 51.4718292],
                        [-0.5674388, 51.472083],
                        [-0.5584772, 51.4721381],
                        [-0.5369145, 51.4720453],
                        [-0.5284539, 51.4719522],
                        [-0.5260669, 51.4719874],
                        [-0.5192185, 51.4719184],
                        [-0.5158773, 51.4719206],
                        [-0.5102196, 51.4720204],
                        [-0.5094796, 51.4720665],
                        [-0.5080859, 51.4724909],
                        [-0.5073651, 51.4725958],
                        [-0.4981474, 51.4727366],
                        [-0.4969539, 51.4728294],
                        [-0.4962354, 51.4729807],
                        [-0.4959009, 51.4732211],
                        [-0.4957063, 51.4734471],
                        [-0.4953607, 51.4743396],
                        [-0.4951827, 51.4745733],
                        [-0.4945767, 51.4750562],
                        [-0.4944126, 51.4752457],
                        [-0.4938929, 51.4761354],
                        [-0.4936878, 51.47634],
                        [-0.4930434, 51.4768079],
                        [-0.4929267, 51.4769451],
                        [-0.492813, 51.4772215],
                        [-0.4928396, 51.4776846],
                        [-0.4929501, 51.4780381],
                        [-0.4931226, 51.4782875],
                        [-0.4939453, 51.4789768],
                        [-0.4945928, 51.4797405],
                        [-0.4953536, 51.4803309],
                        [-0.4956493, 51.4808012],
                        [-0.4959031, 51.4815634],
                        [-0.4963088, 51.4818589],
                        [-0.4971568, 51.4821277],
                        [-0.4976481, 51.4822068],
                        [-0.5031674, 51.4825319],
                        [-0.5057749, 51.4825812],
                        [-0.5061529, 51.4826737],
                        [-0.5070941, 51.4830858],
                        [-0.5077097, 51.4832126],
                        [-0.5105505, 51.4833025],
                        [-0.5227412, 51.4832907],
                        [-0.5255763, 51.48337],
                        [-0.5260863, 51.4834143],
                        [-0.5263776, 51.4834799],
                        [-0.527179, 51.4838539],
                        [-0.527564, 51.4839836],
                        [-0.5287592, 51.4841205],
                        [-0.5296509, 51.4841482],
                        [-0.5452039, 51.4842303],
                        [-0.5527128, 51.4841817],
                        [-0.5681592, 51.4839869],
                        [-0.5966633, 51.4840148],
                        [-0.5994241, 51.4839959],
                        [-0.6007129, 51.4839407],
                        [-0.6015342, 51.4838547],
                        [-0.6038075, 51.4835284],
                        [-0.6067316, 51.4832867],
                        [-0.6080638, 51.4832251],
                        [-0.6260815, 51.4829792],
                        [-0.6297775, 51.4828993],
                        [-0.6312439, 51.4827603],
                        [-0.6354674, 51.4821703],
                        [-0.6370074, 51.4820758],
                        [-0.6481245, 51.4820498],
                        [-0.6512821, 51.4819579],
                        [-0.6521512, 51.4818756],
                        [-0.6543296, 51.4814614],
                        [-0.655802, 51.4812619],
                        [-0.657357, 51.4811954],
                        [-0.6629303, 51.4810669],
                        [-0.6673585, 51.4802765],
                        [-0.6745547, 51.4799728],
                        [-0.6763781, 51.4797504],
                        [-0.6790083, 51.4792106],
                        [-0.6801823, 51.4791219],
                        [-0.6824395, 51.4790278],
                        [-0.6832282, 51.4789381],
                        [-0.6837329, 51.4787693],
                        [-0.6842372, 51.4784839],
                        [-0.6846592, 51.4783003],
                        [-0.6850204, 51.4782094],
                        [-0.6861315, 51.4780882],
                        [-0.6865924, 51.477947],
                        [-0.6879694, 51.4770452],
                        [-0.688209, 51.4767525],
                        [-0.6882885, 51.476502],
                        [-0.6882301, 51.475858],
                        [-0.6881147, 51.4756698],
                        [-0.6879068, 51.4755082],
                        [-0.6874955, 51.4753684],
                        [-0.6860955, 51.4751149],
                        [-0.6849168, 51.474757],
                        [-0.6840375, 51.4746047],
                        [-0.6832961, 51.4745366],
                        [-0.6799479, 51.4744057],
                        [-0.6784373, 51.4742472],
                        [-0.676165, 51.4739219],
                        [-0.6754775, 51.4738652],
                        [-0.6746074, 51.4738196],
                        [-0.6703318, 51.4737819],
                        [-0.6689406, 51.4736706],
                        [-0.6658097, 51.4732836],
                        [-0.6619222, 51.4731472],
                        [-0.6610986, 51.4730455],
                        [-0.658914, 51.4726202],
                        [-0.6575977, 51.4725023],
                        [-0.6532634, 51.4724156],
                        [-0.6372095, 51.4723074],
                        [-0.6349088, 51.4722085],
                        [-0.632912, 51.4719853],
                        [-0.629437, 51.4718078],
                        [-0.6236909, 51.471941]
                    ]
                ]
            ]
        }
    }]
}