export const d100_200_poly = {
    "type": "FeatureCollection",
    "name": "Decrease_100-200_Poly",
    "features": [{
        "type": "Feature",
        "properties": {
            "NAME": "-199",
            "LAYER": "Contour Line, Intermediate",
            "ELEVATION": -199,
            "CLOSED_CON": "YES"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [-0.4033701, 51.4494312],
                        [-0.4031646, 51.4494797],
                        [-0.4028323, 51.4494666],
                        [-0.4021961, 51.4492919],
                        [-0.4009858, 51.4488682],
                        [-0.4006387, 51.4488037],
                        [-0.4003153, 51.4487995],
                        [-0.4000416, 51.4488557],
                        [-0.3995111, 51.449144],
                        [-0.3990523, 51.4496545],
                        [-0.3987488, 51.4505168],
                        [-0.3985826, 51.4508406],
                        [-0.3974861, 51.4521001],
                        [-0.3973528, 51.4523792],
                        [-0.3971716, 51.4530464],
                        [-0.3970085, 51.4532644],
                        [-0.3957912, 51.4542884],
                        [-0.3945204, 51.4557859],
                        [-0.3933195, 51.4566793],
                        [-0.3922878, 51.4577984],
                        [-0.3918562, 51.4581148],
                        [-0.3908055, 51.458643],
                        [-0.3895147, 51.4594278],
                        [-0.388492, 51.4598997],
                        [-0.3873933, 51.4603356],
                        [-0.3856981, 51.461229],
                        [-0.3845931, 51.4621749],
                        [-0.3829813, 51.4632025],
                        [-0.381493, 51.4646393],
                        [-0.381324, 51.465002],
                        [-0.3813592, 51.4656198],
                        [-0.3817049, 51.4662308],
                        [-0.3820085, 51.4665959],
                        [-0.3828451, 51.4672235],
                        [-0.3843814, 51.4680724],
                        [-0.3868826, 51.4691115],
                        [-0.3878579, 51.4693146],
                        [-0.3883648, 51.4693817],
                        [-0.3894282, 51.4694242],
                        [-0.3900043, 51.4693794],
                        [-0.3912047, 51.4690935],
                        [-0.3926697, 51.4688277],
                        [-0.3941128, 51.4684589],
                        [-0.3955264, 51.4682459],
                        [-0.3968216, 51.4679953],
                        [-0.3997839, 51.4672686],
                        [-0.4026798, 51.4668685],
                        [-0.4042842, 51.4664607],
                        [-0.4060365, 51.4661565],
                        [-0.4084514, 51.4658745],
                        [-0.4102279, 51.4655877],
                        [-0.4114893, 51.4655],
                        [-0.4142685, 51.4654558],
                        [-0.4212033, 51.4654198],
                        [-0.422975, 51.4652643],
                        [-0.4244543, 51.4652293],
                        [-0.4348357, 51.4654437],
                        [-0.4359766, 51.465582],
                        [-0.4370235, 51.4656457],
                        [-0.4373242, 51.465695],
                        [-0.437971, 51.4660362],
                        [-0.4382402, 51.466131],
                        [-0.4387053, 51.4662399],
                        [-0.4393493, 51.4663279],
                        [-0.440568, 51.4664124],
                        [-0.4448227, 51.4665397],
                        [-0.4460097, 51.46663],
                        [-0.4466272, 51.4667351],
                        [-0.4475549, 51.4669985],
                        [-0.4487846, 51.4672026],
                        [-0.4500656, 51.4676399],
                        [-0.4530535, 51.4682197],
                        [-0.4543796, 51.4685662],
                        [-0.4557495, 51.4688538],
                        [-0.4570685, 51.4692704],
                        [-0.4574379, 51.4692811],
                        [-0.4578155, 51.4691313],
                        [-0.4580662, 51.4686514],
                        [-0.4583124, 51.4684686],
                        [-0.4587443, 51.4683396],
                        [-0.460186, 51.4680273],
                        [-0.460561, 51.4678695],
                        [-0.4606443, 51.4677927],
                        [-0.4606566, 51.4676862],
                        [-0.4605535, 51.4674988],
                        [-0.4598851, 51.4670258],
                        [-0.4595812, 51.4666496],
                        [-0.4595413, 51.4655719],
                        [-0.4596357, 51.4633188],
                        [-0.4597309, 51.4630958],
                        [-0.4601522, 51.4627477],
                        [-0.4611197, 51.4622296],
                        [-0.4616411, 51.4620061],
                        [-0.4621512, 51.4619254],
                        [-0.4627237, 51.4619061],
                        [-0.4793976, 51.4619591],
                        [-0.4801802, 51.4619095],
                        [-0.4806999, 51.4618375],
                        [-0.4818235, 51.461312],
                        [-0.482425, 51.4611959],
                        [-0.4864655, 51.4611466],
                        [-0.4892659, 51.4610333],
                        [-0.4897115, 51.4608869],
                        [-0.4908754, 51.4602712],
                        [-0.4923434, 51.4593513],
                        [-0.492631, 51.4591274],
                        [-0.492734, 51.4589562],
                        [-0.4926746, 51.4587321],
                        [-0.4922695, 51.4583151],
                        [-0.4918507, 51.4577764],
                        [-0.4916992, 51.4576452],
                        [-0.4913555, 51.4574963],
                        [-0.4908796, 51.4574026],
                        [-0.4903521, 51.457374],
                        [-0.4872792, 51.4573821],
                        [-0.4867398, 51.4573499],
                        [-0.4862539, 51.4571821],
                        [-0.4859062, 51.4569329],
                        [-0.4855901, 51.4567775],
                        [-0.4852312, 51.4566827],
                        [-0.4847997, 51.4566539],
                        [-0.483902, 51.4566667],
                        [-0.4836336, 51.4567124],
                        [-0.483184, 51.4568675],
                        [-0.4824885, 51.4572087],
                        [-0.4819741, 51.4573377],
                        [-0.4752127, 51.4576406],
                        [-0.4732947, 51.4577781],
                        [-0.4693626, 51.457905],
                        [-0.4675078, 51.4581677],
                        [-0.4666147, 51.4583607],
                        [-0.4662608, 51.4583628],
                        [-0.4651772, 51.4581331],
                        [-0.4635477, 51.4579243],
                        [-0.462869, 51.4577607],
                        [-0.4603298, 51.4565357],
                        [-0.4597548, 51.4563813],
                        [-0.4582421, 51.4561116],
                        [-0.4577731, 51.4559488],
                        [-0.4569785, 51.4555655],
                        [-0.4565333, 51.4554496],
                        [-0.4551502, 51.4551868],
                        [-0.4539547, 51.4546122],
                        [-0.4521357, 51.4542578],
                        [-0.4518296, 51.4541236],
                        [-0.4512707, 51.4537859],
                        [-0.4509396, 51.4536667],
                        [-0.4504394, 51.453601],
                        [-0.4479631, 51.4535253],
                        [-0.4466413, 51.4533719],
                        [-0.4461316, 51.4532403],
                        [-0.4453629, 51.4527885],
                        [-0.444872, 51.4526667],
                        [-0.4413013, 51.452594],
                        [-0.4380762, 51.4524676],
                        [-0.437537, 51.4523248],
                        [-0.4368863, 51.4519502],
                        [-0.4365461, 51.4517996],
                        [-0.436035, 51.4517314],
                        [-0.4329876, 51.4516572],
                        [-0.4274203, 51.4515897],
                        [-0.4238006, 51.451575],
                        [-0.4221072, 51.4516138],
                        [-0.4190276, 51.4516203],
                        [-0.4149441, 51.45107],
                        [-0.4143124, 51.4509494],
                        [-0.4125107, 51.4504984],
                        [-0.4100345, 51.4499848],
                        [-0.4066993, 51.4488929],
                        [-0.4063077, 51.4488011],
                        [-0.4059648, 51.4487675],
                        [-0.4052335, 51.4487742],
                        [-0.4046462, 51.4488535],
                        [-0.4041811, 51.4490101],
                        [-0.4033701, 51.4494312]
                    ],
                    [
                        [-0.4223834, 51.4523795],
                        [-0.4308574, 51.4524691],
                        [-0.4360193, 51.4526076],
                        [-0.4364828, 51.4526766],
                        [-0.4374496, 51.4531515],
                        [-0.4379791, 51.4532898],
                        [-0.4430671, 51.4535194],
                        [-0.4446102, 51.4536372],
                        [-0.4455367, 51.453812],
                        [-0.4466256, 51.4539391],
                        [-0.4477016, 51.4542691],
                        [-0.448164, 51.454372],
                        [-0.4506718, 51.4546155],
                        [-0.4512505, 51.4547993],
                        [-0.4526869, 51.4551488],
                        [-0.4541241, 51.4557051],
                        [-0.4558976, 51.4560877],
                        [-0.4566214, 51.4564097],
                        [-0.4571029, 51.4565663],
                        [-0.4576753, 51.4566717],
                        [-0.459101, 51.4568149],
                        [-0.4595095, 51.457018],
                        [-0.459895, 51.4573188],
                        [-0.4607168, 51.4575917],
                        [-0.4613952, 51.4579656],
                        [-0.4618586, 51.4581469],
                        [-0.4623501, 51.4582517],
                        [-0.4638817, 51.4584499],
                        [-0.4645522, 51.4586404],
                        [-0.4653973, 51.4589587],
                        [-0.4661477, 51.4591183],
                        [-0.4664627, 51.4591401],
                        [-0.4671562, 51.459024],
                        [-0.4685942, 51.4585315],
                        [-0.4689318, 51.4584521],
                        [-0.4695106, 51.4583836],
                        [-0.473694, 51.4583689],
                        [-0.4779694, 51.4582704],
                        [-0.4819225, 51.458115],
                        [-0.4824173, 51.4580179],
                        [-0.483387, 51.4575522],
                        [-0.483928, 51.4574523],
                        [-0.484273, 51.4574822],
                        [-0.4845201, 51.4575509],
                        [-0.4851877, 51.4579258],
                        [-0.4856189, 51.4580563],
                        [-0.4868237, 51.4581291],
                        [-0.489417, 51.4580812],
                        [-0.490617, 51.4581108],
                        [-0.4909165, 51.4582029],
                        [-0.4912644, 51.4584547],
                        [-0.4915399, 51.4587632],
                        [-0.4916109, 51.4590357],
                        [-0.4911003, 51.4594274],
                        [-0.4898085, 51.460084],
                        [-0.4894213, 51.4602223],
                        [-0.4889971, 51.4602996],
                        [-0.4850335, 51.460472],
                        [-0.4836708, 51.4605636],
                        [-0.4821851, 51.4606038],
                        [-0.4816536, 51.4606937],
                        [-0.4806038, 51.4609592],
                        [-0.4798366, 51.4610139],
                        [-0.4735737, 51.4611077],
                        [-0.4652603, 51.4613735],
                        [-0.4620456, 51.4614077],
                        [-0.4612686, 51.4614731],
                        [-0.4607362, 51.4615598],
                        [-0.4602201, 51.4617091],
                        [-0.4598736, 51.4618906],
                        [-0.4593813, 51.4622687],
                        [-0.458496, 51.4628314],
                        [-0.4582657, 51.4630706],
                        [-0.4581565, 51.4633108],
                        [-0.458024, 51.4639699],
                        [-0.457895, 51.465735],
                        [-0.4579001, 51.4664005],
                        [-0.4578208, 51.466632],
                        [-0.4575565, 51.4668158],
                        [-0.4571758, 51.4669465],
                        [-0.4565596, 51.4670756],
                        [-0.4561415, 51.4671133],
                        [-0.4554908, 51.4670305],
                        [-0.4533715, 51.4665253],
                        [-0.4502949, 51.4663653],
                        [-0.449641, 51.4662049],
                        [-0.4485351, 51.4658849],
                        [-0.4478475, 51.4656404],
                        [-0.4474675, 51.465557],
                        [-0.4471204, 51.4655295],
                        [-0.441354, 51.465469],
                        [-0.4402965, 51.4654205],
                        [-0.4396636, 51.4652673],
                        [-0.4387722, 51.4648273],
                        [-0.4380066, 51.4646943],
                        [-0.4373186, 51.4646527],
                        [-0.4344449, 51.4646105],
                        [-0.425935, 51.4645481],
                        [-0.4216595, 51.4644267],
                        [-0.4201504, 51.4643032],
                        [-0.4176333, 51.4639015],
                        [-0.4147302, 51.4633739],
                        [-0.4137524, 51.4631556],
                        [-0.410377, 51.4622101],
                        [-0.4088375, 51.4617028],
                        [-0.4066099, 51.460839],
                        [-0.40548, 51.4603152],
                        [-0.4043045, 51.4596687],
                        [-0.4026655, 51.4589976],
                        [-0.4021857, 51.4587364],
                        [-0.4012169, 51.4581091],
                        [-0.4008912, 51.4577772],
                        [-0.4009022, 51.4574385],
                        [-0.4010959, 51.456966],
                        [-0.4015888, 51.4564963],
                        [-0.4020632, 51.4561447],
                        [-0.4031722, 51.4556386],
                        [-0.4039963, 51.4551845],
                        [-0.4051754, 51.4547069],
                        [-0.4062328, 51.454003],
                        [-0.4079189, 51.4530307],
                        [-0.4087032, 51.4527725],
                        [-0.4094271, 51.452642],
                        [-0.410812, 51.4525225],
                        [-0.4132653, 51.4524188],
                        [-0.4161888, 51.4524113],
                        [-0.4190316, 51.4524856],
                        [-0.4223834, 51.4523795]
                    ]
                ],
                [
                    [
                        [-0.4905729, 51.4694244],
                        [-0.4901538, 51.4692752],
                        [-0.4886217, 51.4691639],
                        [-0.4848348, 51.4691071],
                        [-0.4820655, 51.469013],
                        [-0.4817291, 51.4689171],
                        [-0.4810734, 51.4685409],
                        [-0.4807049, 51.4684097],
                        [-0.480097, 51.4683218],
                        [-0.4788262, 51.4682495],
                        [-0.4762803, 51.4682446],
                        [-0.4730717, 51.4681728],
                        [-0.4649375, 51.4681426],
                        [-0.4617303, 51.4680646],
                        [-0.4614372, 51.468074],
                        [-0.4611374, 51.4681649],
                        [-0.4610012, 51.4682716],
                        [-0.4609214, 51.4685012],
                        [-0.461027, 51.4687784],
                        [-0.4613946, 51.4689745],
                        [-0.4629204, 51.4691833],
                        [-0.4632359, 51.4692854],
                        [-0.463467, 51.4694578],
                        [-0.4637614, 51.4699936],
                        [-0.4639004, 51.4701011],
                        [-0.464122, 51.4701905],
                        [-0.4652948, 51.4706],
                        [-0.4668222, 51.4708382],
                        [-0.4686015, 51.4713391],
                        [-0.4691169, 51.4713239],
                        [-0.4699713, 51.4710393],
                        [-0.47036, 51.4709628],
                        [-0.4707426, 51.4709369],
                        [-0.4743906, 51.4709181],
                        [-0.4767328, 51.4709826],
                        [-0.4789471, 51.4709331],
                        [-0.4805481, 51.4709574],
                        [-0.4822164, 51.4709316],
                        [-0.4844435, 51.4708643],
                        [-0.4865473, 51.4707541],
                        [-0.4901973, 51.470731],
                        [-0.4905113, 51.4706859],
                        [-0.4908893, 51.470453],
                        [-0.4909607, 51.4700556],
                        [-0.4909179, 51.469762],
                        [-0.4905729, 51.4694244]
                    ],
                    [
                        [-0.4801597, 51.4692102],
                        [-0.4808555, 51.469438],
                        [-0.4813883, 51.4695351],
                        [-0.4831401, 51.4696138],
                        [-0.4840632, 51.4697133],
                        [-0.4840685, 51.4699368],
                        [-0.4833238, 51.4701466],
                        [-0.4821839, 51.4703641],
                        [-0.4806077, 51.4704638],
                        [-0.478923, 51.4703258],
                        [-0.4762433, 51.4704544],
                        [-0.4745123, 51.4703616],
                        [-0.4721979, 51.4705075],
                        [-0.4703748, 51.4705138],
                        [-0.4699902, 51.4705408],
                        [-0.4691437, 51.4706767],
                        [-0.4687888, 51.4706933],
                        [-0.4673113, 51.4704948],
                        [-0.4661366, 51.4703904],
                        [-0.465979, 51.4703292],
                        [-0.4657818, 51.4701239],
                        [-0.4659152, 51.4698076],
                        [-0.466093, 51.4696537],
                        [-0.466421, 51.4695974],
                        [-0.4671588, 51.4695869],
                        [-0.4687737, 51.4696177],
                        [-0.4704897, 51.4697251],
                        [-0.4719383, 51.4697354],
                        [-0.4745133, 51.4699314],
                        [-0.4759693, 51.4698347],
                        [-0.4767038, 51.4698411],
                        [-0.4781358, 51.4699559],
                        [-0.4784092, 51.4700273],
                        [-0.4787137, 51.4701668],
                        [-0.4789575, 51.4701938],
                        [-0.4791255, 51.4698062],
                        [-0.4792824, 51.469629],
                        [-0.4797836, 51.4693465],
                        [-0.4801597, 51.4692102]
                    ]
                ],
                [
                    [
                        [-0.6556717, 51.4717299],
                        [-0.6434108, 51.4718433],
                        [-0.6390631, 51.4718381],
                        [-0.6373018, 51.4717649],
                        [-0.6359014, 51.4715784],
                        [-0.634398, 51.47131],
                        [-0.6333944, 51.4712124],
                        [-0.6325202, 51.4711755],
                        [-0.6287382, 51.4711249],
                        [-0.610099, 51.4712431],
                        [-0.6061833, 51.4711547],
                        [-0.6041205, 51.4710309],
                        [-0.6027112, 51.4709857],
                        [-0.6008344, 51.471042],
                        [-0.5980977, 51.4712738],
                        [-0.5938413, 51.4713475],
                        [-0.5724002, 51.4713598],
                        [-0.5444588, 51.471514],
                        [-0.5336926, 51.4715527],
                        [-0.5288057, 51.4715296],
                        [-0.5280622, 51.4715422],
                        [-0.5263326, 51.4716715],
                        [-0.5250679, 51.471639],
                        [-0.523536, 51.4715477],
                        [-0.5222485, 51.4715278],
                        [-0.511346, 51.4715405],
                        [-0.5096643, 51.4715739],
                        [-0.509047, 51.4716368],
                        [-0.5077846, 51.4720315],
                        [-0.507437, 51.472067],
                        [-0.5047248, 51.472068],
                        [-0.5006418, 51.472141],
                        [-0.4987017, 51.4720942],
                        [-0.4971802, 51.4721806],
                        [-0.4965507, 51.4722677],
                        [-0.4963274, 51.4723362],
                        [-0.4958226, 51.472651],
                        [-0.4952423, 51.4732251],
                        [-0.4949823, 51.473551],
                        [-0.4946392, 51.4741227],
                        [-0.4939648, 51.4747848],
                        [-0.4934049, 51.4756785],
                        [-0.4924695, 51.476564],
                        [-0.4922879, 51.4770088],
                        [-0.4922921, 51.4777983],
                        [-0.4924381, 51.4783379],
                        [-0.49269, 51.4787749],
                        [-0.4936343, 51.4795105],
                        [-0.4941104, 51.4802184],
                        [-0.4948573, 51.4809648],
                        [-0.4956558, 51.4822473],
                        [-0.4958549, 51.4824731],
                        [-0.496229, 51.48267],
                        [-0.4969445, 51.4828108],
                        [-0.4976222, 51.4828855],
                        [-0.5007581, 51.4830396],
                        [-0.5033034, 51.4832628],
                        [-0.505595, 51.483327],
                        [-0.5059231, 51.4833772],
                        [-0.5061914, 51.4834619],
                        [-0.5069921, 51.4838446],
                        [-0.5075182, 51.4839536],
                        [-0.5102514, 51.4840644],
                        [-0.5116916, 51.4840827],
                        [-0.5230758, 51.4839716],
                        [-0.5257314, 51.4841761],
                        [-0.5273509, 51.4845012],
                        [-0.5292553, 51.4846245],
                        [-0.5330758, 51.4846111],
                        [-0.5355676, 51.4846708],
                        [-0.537764, 51.4846606],
                        [-0.5418905, 51.4847265],
                        [-0.5619968, 51.4848342],
                        [-0.5792876, 51.4848097],
                        [-0.5814886, 51.484776],
                        [-0.5836845, 51.4846696],
                        [-0.589498, 51.484524],
                        [-0.6009038, 51.4846116],
                        [-0.6027303, 51.4845937],
                        [-0.6055219, 51.484514],
                        [-0.6067133, 51.4843923],
                        [-0.6083531, 51.4841696],
                        [-0.6098799, 51.4841023],
                        [-0.6295277, 51.4840444],
                        [-0.6326134, 51.4838924],
                        [-0.6342255, 51.4838621],
                        [-0.6372138, 51.4837362],
                        [-0.6543782, 51.4834769],
                        [-0.6571616, 51.4833873],
                        [-0.6600114, 51.4832449],
                        [-0.663994, 51.4829098],
                        [-0.6659372, 51.4826718],
                        [-0.6690327, 51.4824533],
                        [-0.6719916, 51.4823724],
                        [-0.6802309, 51.4823072],
                        [-0.6834417, 51.4822293],
                        [-0.6859864, 51.482059],
                        [-0.6872213, 51.4818179],
                        [-0.6881734, 51.4817144],
                        [-0.6902843, 51.481592],
                        [-0.694763, 51.4814529],
                        [-0.6988648, 51.4810835],
                        [-0.7006557, 51.480864],
                        [-0.7029766, 51.480735],
                        [-0.704867, 51.4806708],
                        [-0.7078501, 51.4804888],
                        [-0.7101164, 51.4802441],
                        [-0.7130108, 51.4798294],
                        [-0.7151098, 51.4796805],
                        [-0.7177313, 51.4793873],
                        [-0.7193972, 51.4791235],
                        [-0.7220647, 51.4785745],
                        [-0.7236695, 51.4783663],
                        [-0.7249741, 51.4781263],
                        [-0.7264491, 51.4776099],
                        [-0.7278935, 51.4773409],
                        [-0.7282526, 51.4772243],
                        [-0.7284768, 51.4771292],
                        [-0.7288057, 51.4768866],
                        [-0.729063, 51.476476],
                        [-0.7290454, 51.4762747],
                        [-0.7289199, 51.4761929],
                        [-0.7274022, 51.4758509],
                        [-0.7263981, 51.4755024],
                        [-0.7254318, 51.4752836],
                        [-0.723716, 51.4750588],
                        [-0.7217065, 51.4748508],
                        [-0.7191878, 51.4743153],
                        [-0.7150242, 51.4740469],
                        [-0.7065384, 51.4738708],
                        [-0.6962491, 51.47382],
                        [-0.6892526, 51.4739103],
                        [-0.6861146, 51.4737728],
                        [-0.6823171, 51.4737265],
                        [-0.6814135, 51.4736661],
                        [-0.6791804, 51.4733605],
                        [-0.6774887, 51.4732275],
                        [-0.6751936, 51.4731229],
                        [-0.6719647, 51.473076],
                        [-0.6703994, 51.4730133],
                        [-0.6691434, 51.4728596],
                        [-0.6673606, 51.4725155],
                        [-0.6664388, 51.4723872],
                        [-0.6657017, 51.4723437],
                        [-0.6631859, 51.4723113],
                        [-0.6621409, 51.4722481],
                        [-0.658714, 51.4717706],
                        [-0.6556717, 51.4717299]
                    ],
                    [
                        [-0.5670816, 51.4720748],
                        [-0.5809461, 51.4718242],
                        [-0.5973691, 51.4719232],
                        [-0.6027569, 51.4717862],
                        [-0.6056293, 51.4718967],
                        [-0.6102334, 51.4719813],
                        [-0.6182266, 51.4719745],
                        [-0.6237005, 51.4719271],
                        [-0.6294496, 51.4717892],
                        [-0.6329304, 51.4719738],
                        [-0.6349162, 51.4722007],
                        [-0.6372163, 51.4723006],
                        [-0.6532697, 51.4724093],
                        [-0.6576992, 51.4725006],
                        [-0.6589192, 51.4726115],
                        [-0.6611099, 51.4730373],
                        [-0.6619299, 51.4731392],
                        [-0.6657816, 51.4732723],
                        [-0.6689455, 51.4736626],
                        [-0.6703722, 51.4737757],
                        [-0.6746365, 51.4738133],
                        [-0.6754831, 51.4738565],
                        [-0.6761703, 51.4739134],
                        [-0.6784443, 51.4742397],
                        [-0.6799544, 51.4743989],
                        [-0.6833002, 51.4745302],
                        [-0.6841607, 51.4746125],
                        [-0.6849238, 51.474749],
                        [-0.6861102, 51.475109],
                        [-0.687501, 51.4753621],
                        [-0.6879116, 51.475502],
                        [-0.6881248, 51.4756668],
                        [-0.6882398, 51.4758548],
                        [-0.6882988, 51.4764981],
                        [-0.6882203, 51.4767492],
                        [-0.687976, 51.4770495],
                        [-0.6865997, 51.4779522],
                        [-0.6861239, 51.4780972],
                        [-0.6850286, 51.4782165],
                        [-0.6846674, 51.4783071],
                        [-0.6842446, 51.4784908],
                        [-0.683704, 51.4787933],
                        [-0.6832301, 51.478945],
                        [-0.6824337, 51.4790357],
                        [-0.6801724, 51.4791313],
                        [-0.6790042, 51.4792201],
                        [-0.676369, 51.4797617],
                        [-0.6745471, 51.4799831],
                        [-0.6673749, 51.4802845],
                        [-0.6629227, 51.4810769],
                        [-0.6573499, 51.4812024],
                        [-0.6558182, 51.4812682],
                        [-0.6544685, 51.4814467],
                        [-0.6521579, 51.4818833],
                        [-0.6512745, 51.4819652],
                        [-0.6481206, 51.4820555],
                        [-0.6370032, 51.4820817],
                        [-0.6354666, 51.4821789],
                        [-0.6312525, 51.4827681],
                        [-0.6297719, 51.4829069],
                        [-0.6257033, 51.4829929],
                        [-0.6080563, 51.4832323],
                        [-0.6067259, 51.4832945],
                        [-0.6038181, 51.4835361],
                        [-0.6015241, 51.4838639],
                        [-0.6005648, 51.4839585],
                        [-0.5994184, 51.4840015],
                        [-0.5966598, 51.4840199],
                        [-0.5685188, 51.4839919],
                        [-0.5530738, 51.4841846],
                        [-0.5455639, 51.4842346],
                        [-0.529648, 51.4841526],
                        [-0.5287551, 51.4841247],
                        [-0.5275426, 51.4839854],
                        [-0.5271746, 51.4838588],
                        [-0.5263728, 51.4834848],
                        [-0.5260832, 51.4834196],
                        [-0.5255712, 51.4833752],
                        [-0.5227362, 51.4832956],
                        [-0.5105461, 51.483309],
                        [-0.5077054, 51.4832192],
                        [-0.5070894, 51.4830911],
                        [-0.5061476, 51.4826799],
                        [-0.5057383, 51.4825851],
                        [-0.5031595, 51.4825399],
                        [-0.4976423, 51.4822159],
                        [-0.4971498, 51.4821356],
                        [-0.4963036, 51.4818656],
                        [-0.4958977, 51.4815678],
                        [-0.4956417, 51.4808087],
                        [-0.4953463, 51.4803362],
                        [-0.4945814, 51.4797432],
                        [-0.4939394, 51.478981],
                        [-0.4931177, 51.4782928],
                        [-0.4929438, 51.4780401],
                        [-0.4928326, 51.4776857],
                        [-0.4928091, 51.4772195],
                        [-0.4929178, 51.4769431],
                        [-0.4938828, 51.4761355],
                        [-0.494404, 51.4752452],
                        [-0.4946387, 51.4749949],
                        [-0.4951738, 51.4745735],
                        [-0.4953527, 51.4743374],
                        [-0.4957007, 51.4734464],
                        [-0.4958885, 51.473224],
                        [-0.4962377, 51.4729746],
                        [-0.4969462, 51.4728236],
                        [-0.4981551, 51.4727292],
                        [-0.5073694, 51.4725897],
                        [-0.5080849, 51.472485],
                        [-0.5094576, 51.4720635],
                        [-0.5102244, 51.4720138],
                        [-0.5156672, 51.4719169],
                        [-0.5192216, 51.4719139],
                        [-0.5260693, 51.4719838],
                        [-0.5284591, 51.4719471],
                        [-0.5369187, 51.4720392],
                        [-0.5584833, 51.4721293],
                        [-0.5670816, 51.4720748]
                    ]
                ],
                [
                    [
                        [-0.3632748, 51.4375424],
                        [-0.3628646, 51.4364982],
                        [-0.3623825, 51.4359124],
                        [-0.361902, 51.4354723],
                        [-0.3609133, 51.4345791],
                        [-0.3597238, 51.4338673],
                        [-0.3592068, 51.433632],
                        [-0.3579823, 51.4332149],
                        [-0.3575017, 51.4331814],
                        [-0.3564879, 51.4333082],
                        [-0.3561296, 51.4334109],
                        [-0.3556413, 51.4336211],
                        [-0.3553577, 51.4338102],
                        [-0.3552792, 51.4339101],
                        [-0.3551694, 51.4342638],
                        [-0.3551408, 51.4347158],
                        [-0.3552437, 51.4354003],
                        [-0.3554238, 51.4359733],
                        [-0.3559982, 51.4373048],
                        [-0.3566185, 51.4382841],
                        [-0.3570042, 51.4393447],
                        [-0.3577035, 51.4403252],
                        [-0.3582409, 51.4412505],
                        [-0.3594354, 51.442709],
                        [-0.3609628, 51.4438937],
                        [-0.3618082, 51.4447198],
                        [-0.3621801, 51.4449312],
                        [-0.3628455, 51.445189],
                        [-0.3632934, 51.4452629],
                        [-0.36368, 51.4451408],
                        [-0.363987, 51.4448436],
                        [-0.3641242, 51.4446269],
                        [-0.3646281, 51.4430358],
                        [-0.3646383, 51.4425875],
                        [-0.3643417, 51.4407847],
                        [-0.3638544, 51.4393067],
                        [-0.3637596, 51.4384066],
                        [-0.3632748, 51.4375424]
                    ]
                ],
                [
                    [
                        [-0.3687249, 51.4797457],
                        [-0.3686447, 51.4789724],
                        [-0.3683841, 51.4783886],
                        [-0.3680422, 51.4780467],
                        [-0.3673494, 51.477686],
                        [-0.3666092, 51.4771397],
                        [-0.3662627, 51.4770214],
                        [-0.3653565, 51.476893],
                        [-0.364532, 51.4767118],
                        [-0.3638545, 51.4766306],
                        [-0.3631127, 51.4766448],
                        [-0.3621437, 51.4768038],
                        [-0.3606897, 51.4772692],
                        [-0.3599935, 51.4776279],
                        [-0.3592478, 51.4779368],
                        [-0.3583123, 51.4784639],
                        [-0.3571418, 51.478959],
                        [-0.3554145, 51.4799121],
                        [-0.3530793, 51.4814009],
                        [-0.3522281, 51.4818825],
                        [-0.3517353, 51.4821133],
                        [-0.3503966, 51.4826015],
                        [-0.3492643, 51.4832475],
                        [-0.3485135, 51.4838273],
                        [-0.3472454, 51.4844793],
                        [-0.3455358, 51.4856818],
                        [-0.3438135, 51.4866184],
                        [-0.3424699, 51.4875719],
                        [-0.341573, 51.4880199],
                        [-0.3403303, 51.4885351],
                        [-0.3388754, 51.489346],
                        [-0.3385424, 51.4895668],
                        [-0.337573, 51.4903633],
                        [-0.3368259, 51.4907902],
                        [-0.3357572, 51.4914792],
                        [-0.3344086, 51.492189],
                        [-0.3332368, 51.4930471],
                        [-0.3318614, 51.4937397],
                        [-0.3312902, 51.4940713],
                        [-0.325885, 51.4974429],
                        [-0.32094, 51.5006459],
                        [-0.3196661, 51.5016522],
                        [-0.3184495, 51.5025006],
                        [-0.3164685, 51.5041674],
                        [-0.3157026, 51.5049646],
                        [-0.3155683, 51.5051716],
                        [-0.3155994, 51.5053857],
                        [-0.3160534, 51.5060967],
                        [-0.3162551, 51.5063002],
                        [-0.3164571, 51.50639],
                        [-0.3167842, 51.506417],
                        [-0.3178419, 51.5063116],
                        [-0.3196576, 51.5058507],
                        [-0.3207448, 51.5056194],
                        [-0.3222171, 51.5051645],
                        [-0.3247446, 51.5045884],
                        [-0.3253231, 51.5044123],
                        [-0.3265989, 51.5038888],
                        [-0.3276984, 51.5033199],
                        [-0.3293161, 51.5028065],
                        [-0.3302751, 51.5023725],
                        [-0.3315833, 51.5018659],
                        [-0.3335078, 51.501008],
                        [-0.3353384, 51.5003896],
                        [-0.3376191, 51.4991341],
                        [-0.3389952, 51.498167],
                        [-0.3406494, 51.4974291],
                        [-0.3430262, 51.4962039],
                        [-0.34394, 51.4958587],
                        [-0.3443087, 51.4956772],
                        [-0.3446072, 51.4954689],
                        [-0.3453952, 51.4947797],
                        [-0.3470691, 51.4937765],
                        [-0.3480933, 51.4932996],
                        [-0.349738, 51.4923197],
                        [-0.3507672, 51.4918262],
                        [-0.3517639, 51.4911663],
                        [-0.3541381, 51.4901958],
                        [-0.3555534, 51.4894365],
                        [-0.3564402, 51.4888797],
                        [-0.3574279, 51.4883457],
                        [-0.3588685, 51.4874641],
                        [-0.3599409, 51.4867245],
                        [-0.3621453, 51.485455],
                        [-0.362979, 51.4848755],
                        [-0.3636302, 51.484525],
                        [-0.3643176, 51.4840436],
                        [-0.3650304, 51.4836564],
                        [-0.3658233, 51.4830631],
                        [-0.3665429, 51.4826006],
                        [-0.3677553, 51.4816377],
                        [-0.3684263, 51.4807421],
                        [-0.3686195, 51.48032],
                        [-0.3687249, 51.4797457]
                    ]
                ]
            ]
        }
    }]
}