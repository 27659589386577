import React, { useEffect, useState } from 'react';
import { useMapContext } from './MapContext';

const PostcodeChecker = ({setShowFewer, setShowGreater, setMarker}) => {
  const mapRef = useMapContext();

  const [postcode, setPostcode] = useState('');
  const [result, setResult] = useState({title:'', text:''});
  const [show, setShow] = useState(false);
  const [d10_20, setD10_20 ] = useState([]);
  const [d20_50, setD20_50 ] = useState([]);
  const [d50_100, setD50_100 ] = useState([]);
  const [d100_200, setD100_200 ] = useState([]);
  const [d200, setD200 ] = useState([]);
  const [i10_20, setI10_20 ] = useState([]);
  const [i20_50, setI20_50 ] = useState([]);
  const [i50_100, setI50_100 ] = useState([]);
  const [i100_200, setI100_200 ] = useState([]);
  const [i200, setI200 ] = useState([]);
  
  const [error, setError ] = useState('');

  useEffect(() => {
    //Get decrease postcodes
    fetch('./data/decrease10-20.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setD10_20(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/decrease20-50.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setD20_50(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/decrease50-100.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setD50_100(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/decrease100-200.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setD100_200(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/decrease200.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setD200(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));


    //Get increase postcodes
    fetch('./data/increase10-20.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setI10_20(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/increase20-50.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setI20_50(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/increase50-100.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setI50_100(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/increase100-200.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setI100_200(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));
    fetch('./data/increase200.csv')
      .then(response => response.text())
      .then(text => {
        const rows = text.split('\n').map(row => {
          const [postcode, lat, lng] = row.split(',');
          return { postcode: postcode, lat: parseFloat(lat), lng: parseFloat(lng) };
        });
        setI200(rows);
      })
      .catch(error => console.error('Error fetching the CSV file:', error));

    



  }, []);

  const checkPostcode = () => {

    if(!validatePostcode(postcode))
      return;
    let found = false;

    const checkOrder = [
      {
        set: i200,
        message: {title:'Increase 200', text:'Heathrow anticipates that this area will be more overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: i100_200,
        message: {title:'Increase 100 - 200', text:'Heathrow anticipates that this area will be more overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: i50_100,
        message: {title:'Increase 50 - 100', text:'Heathrow anticipates that this area will be more overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: i20_50,
        message: {title:'Increase 20 - 50', text:'Heathrow anticipates that this area will be more overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: i10_20,
        message: {title:'Increase 10 - 20', text:'Heathrow anticipates that this area will be more overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },

      {
        set: d10_20,
        message: {title:'Decrease 10 - 20', text:'Heathrow anticipates that this area will be less overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },

      {
        set: d20_50,
        message: {title:'Decrease 20 - 50', text:'Heathrow anticipates that this area will be less overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: d50_100,
        message: {title:'Decrease 50 - 100', text:'Heathrow anticipates that this area will be less overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: d100_200,
        message: {title:'Decrease 100 - 200', text:'Heathrow anticipates that this area will be less overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
      {
        set: d200,
        message: {title:'Decrease 200', text:'Heathrow anticipates that this area will be less overflown on easterly operation as a result of our proposals, based on the assessment in the Environmental Statement.'}
      },
     
    ];

    checkOrder.some((check) => {
      let result = check.set.filter(row => formatString(row.postcode) === formatString(postcode));
      if(result.length > 0){
        found = true;
        setResult(check.message);
        setShow(true);
        //fly to postcode
        handleFlyTo([result[0].lat, result[0].lng]);
        return true;
      }
      return false; 
    });
  
    if(!found){
      setResult({title:'No change', text:'Heathrow anticipates that this area will not experience change as a result of the proposals, based on the assessment in the Environmental Statement.'});
      if(setShowGreater !== undefined)
        setShowGreater(true);
      if(setShowFewer !== undefined)
      setShowFewer(true);
      if(setMarker !== undefined)
      setMarker(false);
      handleFlyTo([51.47271, -0.53106], 11);
      setShow(true);
    }
    
  };

  const handleFlyTo = ([lat, long], zoom = 14) => {
    if (mapRef && mapRef.current) {
      mapRef.current.flyTo([lat, long], zoom); // New position and zoom level
    }
  };

  const handleKeyDown = (event) =>{ 
    setShow(false);
    if(event.key === 'Enter'){
        checkPostcode();
    }
  }

  const formatString = (str) => {
    return str.replace(/\s+/g, '').toUpperCase();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setError('');
    setPostcode(value);
  }

  const validatePostcode = (value) => {

    if(value === ""){
      setError('Please enter a postcode.');
      return false;
    }
    setError('');
    const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
    if (postcodeRegex.test(value)) {
      setError('');
      return true;
    } else {
      setError('Please enter a complete valid postcode.');
      return false;
    }
  };

  return (
    <div className="survey__panel question--spot-bot">
        <div className="question__question single-text postcode">
            <label htmlFor="cav-postcode">Easterly Alternation, how will it affect you?</label>
            <input
                id="cav-postcode"
                type="text"
                value={postcode}
                autoComplete='false'
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Enter postcode"
            />
            <button onClick={checkPostcode}>Check</button>
            {show && error == '' && <div className='postcode-result'>
                <h2>{result.title}</h2>
                {result.text}
                <a href="https://www.heathrow.com/company/local-community/noise/operations/easterly-alternation" target='_top' className="btn">Find out more</a>
            </div>}
            {error &&
            <div className='error'>
              {error}
            </div>
            }
        </div>
    </div>
  );
};

export default PostcodeChecker;
