export const i200_poly = {
    "type": "FeatureCollection",
    "name": "Increase_200_Poly",
    "features": [{
        "type": "Feature",
        "properties": {
            "NAME": "200",
            "LAYER": "Contour Line, Intermediate",
            "ELEVATION": 200,
            "CLOSED_CON": "YES"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [-0.6466578, 51.4592981],
                        [-0.640849, 51.4592985],
                        [-0.6381964, 51.4591872],
                        [-0.6364483, 51.4589956],
                        [-0.6342798, 51.4586669],
                        [-0.6334401, 51.4585769],
                        [-0.6320372, 51.4585064],
                        [-0.6295419, 51.4584523],
                        [-0.6215977, 51.4583981],
                        [-0.6072367, 51.4584105],
                        [-0.6047914, 51.4583078],
                        [-0.603472, 51.4581407],
                        [-0.6019646, 51.4580011],
                        [-0.6004939, 51.4578002],
                        [-0.5997989, 51.4577672],
                        [-0.5877619, 51.4578128],
                        [-0.5789036, 51.4577649],
                        [-0.5584114, 51.4578371],
                        [-0.5401623, 51.458043],
                        [-0.5244084, 51.4581671],
                        [-0.523637, 51.4582266],
                        [-0.5229583, 51.4583349],
                        [-0.5224068, 51.4584822],
                        [-0.5217014, 51.4587337],
                        [-0.5213136, 51.4588231],
                        [-0.5201542, 51.4589259],
                        [-0.5085001, 51.4589406],
                        [-0.5057051, 51.4590019],
                        [-0.5051159, 51.4590812],
                        [-0.5038501, 51.4594684],
                        [-0.502356, 51.4597593],
                        [-0.4996748, 51.4599142],
                        [-0.4967186, 51.4601692],
                        [-0.4954135, 51.4602417],
                        [-0.4940914, 51.4604137],
                        [-0.4935636, 51.4606267],
                        [-0.4933999, 51.4607366],
                        [-0.4932408, 51.4609349],
                        [-0.4929718, 51.4623369],
                        [-0.4927326, 51.4627374],
                        [-0.4923637, 51.462978],
                        [-0.4914712, 51.4632909],
                        [-0.4911577, 51.4634414],
                        [-0.4908235, 51.4636538],
                        [-0.4906515, 51.4638209],
                        [-0.490383, 51.4641766],
                        [-0.4902728, 51.4644124],
                        [-0.4901602, 51.4652912],
                        [-0.4903598, 51.4659136],
                        [-0.4905969, 51.4662374],
                        [-0.4918682, 51.4668013],
                        [-0.4923706, 51.4671826],
                        [-0.4925604, 51.4674717],
                        [-0.4927152, 51.4678629],
                        [-0.4928431, 51.468718],
                        [-0.492994, 51.4691065],
                        [-0.4934008, 51.4695336],
                        [-0.4937948, 51.4697205],
                        [-0.4948853, 51.4699075],
                        [-0.4976813, 51.469857],
                        [-0.5002892, 51.4699722],
                        [-0.5035266, 51.4697983],
                        [-0.5046108, 51.4699525],
                        [-0.5060291, 51.4702879],
                        [-0.5067047, 51.4703587],
                        [-0.5232467, 51.4703672],
                        [-0.5260906, 51.4704538],
                        [-0.5279922, 51.4703265],
                        [-0.5304921, 51.4703011],
                        [-0.5341347, 51.470153],
                        [-0.54061, 51.4700203],
                        [-0.5506851, 51.4699299],
                        [-0.5710558, 51.4699418],
                        [-0.5765275, 51.4699121],
                        [-0.5818586, 51.4698189],
                        [-0.5852395, 51.4696915],
                        [-0.5905867, 51.4696139],
                        [-0.5981497, 51.4695612],
                        [-0.6014071, 51.4694904],
                        [-0.6236167, 51.4696391],
                        [-0.6260587, 51.4695587],
                        [-0.6271804, 51.4694578],
                        [-0.6282634, 51.4692547],
                        [-0.6287242, 51.4692044],
                        [-0.6319561, 51.4690464],
                        [-0.6427268, 51.4689594],
                        [-0.6518152, 51.4687401],
                        [-0.6540917, 51.4686039],
                        [-0.6565439, 51.4685084],
                        [-0.6575513, 51.4683991],
                        [-0.6588353, 51.4681668],
                        [-0.6594317, 51.4680946],
                        [-0.6619108, 51.467963],
                        [-0.6662256, 51.4678024],
                        [-0.6669847, 51.4676882],
                        [-0.668434, 51.4673253],
                        [-0.6691065, 51.4672226],
                        [-0.6713397, 51.4673155],
                        [-0.6731481, 51.467325],
                        [-0.6760247, 51.4671447],
                        [-0.679182, 51.4670817],
                        [-0.6823527, 51.466957],
                        [-0.6832081, 51.4668189],
                        [-0.6844459, 51.4665129],
                        [-0.6849871, 51.4664251],
                        [-0.6878292, 51.4662974],
                        [-0.6888267, 51.4661695],
                        [-0.689358, 51.46603],
                        [-0.6904201, 51.4656057],
                        [-0.6918822, 51.4652847],
                        [-0.6922221, 51.4651357],
                        [-0.6924615, 51.4648967],
                        [-0.6925744, 51.464293],
                        [-0.6927005, 51.4640497],
                        [-0.6929854, 51.4637953],
                        [-0.6937252, 51.4632681],
                        [-0.6937303, 51.4632245],
                        [-0.6935905, 51.4631127],
                        [-0.6933106, 51.4629755],
                        [-0.6912668, 51.4625275],
                        [-0.6897785, 51.4620031],
                        [-0.6889734, 51.4618892],
                        [-0.6863901, 51.4617097],
                        [-0.6857519, 51.4615624],
                        [-0.6844652, 51.4611055],
                        [-0.6839101, 51.4609887],
                        [-0.6832404, 51.4609293],
                        [-0.6798594, 51.4608209],
                        [-0.6784887, 51.4607349],
                        [-0.6772127, 51.4605982],
                        [-0.6749024, 51.4602474],
                        [-0.6722025, 51.460084],
                        [-0.6707423, 51.4600316],
                        [-0.6651047, 51.4600048],
                        [-0.6636996, 51.4599554],
                        [-0.6621711, 51.4598395],
                        [-0.6611387, 51.4597149],
                        [-0.6591285, 51.459356],
                        [-0.6567705, 51.4592281],
                        [-0.6466578, 51.4592981]
                    ],
                    [
                        [-0.4992127, 51.4679363],
                        [-0.4990224, 51.4678871],
                        [-0.498877, 51.4677943],
                        [-0.4981057, 51.4671962],
                        [-0.4988753, 51.467203],
                        [-0.4991882, 51.4673742],
                        [-0.4992752, 51.4674935],
                        [-0.4992864, 51.467696],
                        [-0.4992726, 51.4678843],
                        [-0.4992127, 51.4679363]
                    ]
                ],
                [
                    [
                        [-0.4929075, 51.471804],
                        [-0.4929709, 51.4718797],
                        [-0.4931974, 51.4719283],
                        [-0.4935792, 51.4719204],
                        [-0.4938457, 51.4718626],
                        [-0.4943223, 51.471633],
                        [-0.4943003, 51.4714251],
                        [-0.4939385, 51.4712673],
                        [-0.4936019, 51.4712469],
                        [-0.4933816, 51.4712986],
                        [-0.4930216, 51.4715059],
                        [-0.4929251, 51.47162],
                        [-0.4929075, 51.471804]
                    ]
                ],
                [
                    [
                        [-0.486985, 51.4724939],
                        [-0.4868047, 51.4723984],
                        [-0.4864682, 51.4723641],
                        [-0.4861323, 51.4723856],
                        [-0.4860084, 51.4725103],
                        [-0.4860699, 51.4726953],
                        [-0.4863486, 51.4727312],
                        [-0.4868181, 51.4726788],
                        [-0.4868991, 51.472641],
                        [-0.486985, 51.4724939]
                    ]
                ],
                [
                    [
                        [-0.4832477, 51.4726299],
                        [-0.4830639, 51.4725142],
                        [-0.4828696, 51.4724975],
                        [-0.4826184, 51.4726171],
                        [-0.4829137, 51.4727101],
                        [-0.4832477, 51.4726299]
                    ]
                ],
                [
                    [
                        [-0.4677085, 51.4728358],
                        [-0.4702149, 51.4728873],
                        [-0.4729562, 51.4728511],
                        [-0.4749501, 51.4727424],
                        [-0.4760025, 51.4727024],
                        [-0.4774377, 51.4727282],
                        [-0.4778243, 51.4726954],
                        [-0.4785679, 51.4725647],
                        [-0.4778725, 51.4724046],
                        [-0.4774026, 51.4723415],
                        [-0.4759264, 51.4723625],
                        [-0.4734872, 51.4722414],
                        [-0.4706335, 51.4721662],
                        [-0.4689401, 51.4722038],
                        [-0.4675044, 51.4721835],
                        [-0.4668217, 51.4721369],
                        [-0.4659053, 51.4719718],
                        [-0.4655323, 51.4719401],
                        [-0.4645438, 51.4719328],
                        [-0.4634921, 51.4719929],
                        [-0.4630803, 51.4720577],
                        [-0.462862, 51.4721289],
                        [-0.4627032, 51.4722198],
                        [-0.4623225, 51.4725632],
                        [-0.4613141, 51.4729891],
                        [-0.4610415, 51.4732067],
                        [-0.4606825, 51.4733802],
                        [-0.4602082, 51.4734594],
                        [-0.4591325, 51.4734925],
                        [-0.4587212, 51.473546],
                        [-0.4583911, 51.4736377],
                        [-0.4577639, 51.4740456],
                        [-0.4574369, 51.4741903],
                        [-0.4570178, 51.4742765],
                        [-0.456078, 51.4743322],
                        [-0.4556448, 51.4744252],
                        [-0.45542, 51.4745107],
                        [-0.454853, 51.4749216],
                        [-0.4544968, 51.4751002],
                        [-0.454214, 51.4751727],
                        [-0.4533104, 51.4752192],
                        [-0.4528997, 51.4752821],
                        [-0.4525027, 51.4754401],
                        [-0.4517922, 51.4759079],
                        [-0.4514531, 51.4760511],
                        [-0.4509321, 51.4761144],
                        [-0.4473226, 51.4762963],
                        [-0.4471117, 51.4763383],
                        [-0.4467123, 51.4764939],
                        [-0.445849, 51.4769467],
                        [-0.4452904, 51.4770994],
                        [-0.444205, 51.4771821],
                        [-0.4412328, 51.4772666],
                        [-0.4387782, 51.4774174],
                        [-0.4384603, 51.4774662],
                        [-0.4380972, 51.4775768],
                        [-0.436982, 51.4781075],
                        [-0.4362577, 51.4781891],
                        [-0.4323202, 51.4783201],
                        [-0.4256741, 51.4783866],
                        [-0.4213529, 51.4783939],
                        [-0.4183032, 51.4783168],
                        [-0.415597, 51.4783134],
                        [-0.4146307, 51.478371],
                        [-0.4121422, 51.4786395],
                        [-0.408307, 51.4791832],
                        [-0.4064618, 51.4796459],
                        [-0.404998, 51.479873],
                        [-0.4029952, 51.4801044],
                        [-0.4003986, 51.4805505],
                        [-0.3992422, 51.480841],
                        [-0.3983833, 51.4809644],
                        [-0.3976666, 51.4811397],
                        [-0.3972822, 51.4815152],
                        [-0.3971123, 51.4819086],
                        [-0.3971826, 51.4828916],
                        [-0.3972709, 51.4832007],
                        [-0.3974845, 51.4836701],
                        [-0.3980006, 51.4842133],
                        [-0.3983233, 51.4846838],
                        [-0.3993636, 51.4853922],
                        [-0.4006267, 51.4866276],
                        [-0.400957, 51.486865],
                        [-0.4021391, 51.4874815],
                        [-0.4033664, 51.4880498],
                        [-0.4038717, 51.4882045],
                        [-0.4049943, 51.4884236],
                        [-0.4062935, 51.4888761],
                        [-0.4069221, 51.4890066],
                        [-0.4080349, 51.4891663],
                        [-0.4103306, 51.489633],
                        [-0.4121879, 51.4898712],
                        [-0.414762, 51.4900639],
                        [-0.4163851, 51.4900909],
                        [-0.4218383, 51.4902959],
                        [-0.422349, 51.4903539],
                        [-0.4236046, 51.4905823],
                        [-0.4253432, 51.4906992],
                        [-0.4328298, 51.4906234],
                        [-0.4357251, 51.4904498],
                        [-0.4363391, 51.490357],
                        [-0.4376592, 51.4897938],
                        [-0.4384173, 51.4896365],
                        [-0.4395203, 51.4895832],
                        [-0.4413371, 51.4894072],
                        [-0.4439736, 51.4893145],
                        [-0.4463067, 51.4891409],
                        [-0.4477919, 51.4890973],
                        [-0.4484913, 51.4890292],
                        [-0.4494413, 51.4888116],
                        [-0.4501136, 51.4885063],
                        [-0.4506597, 51.4883261],
                        [-0.4510478, 51.488257],
                        [-0.4519964, 51.488215],
                        [-0.4523193, 51.4881598],
                        [-0.4530417, 51.4877599],
                        [-0.4536015, 51.4875728],
                        [-0.4546673, 51.4874246],
                        [-0.4560587, 51.4872921],
                        [-0.4564716, 51.4872169],
                        [-0.4567721, 51.4870966],
                        [-0.4571817, 51.4867688],
                        [-0.4575635, 51.4866025],
                        [-0.4579688, 51.4865204],
                        [-0.4583559, 51.4864878],
                        [-0.4596813, 51.4864771],
                        [-0.4612068, 51.48572],
                        [-0.4615968, 51.4853592],
                        [-0.461956, 51.4851221],
                        [-0.4623362, 51.4850019],
                        [-0.4626662, 51.4849465],
                        [-0.4636967, 51.4849306],
                        [-0.4641143, 51.4848564],
                        [-0.4650652, 51.4843797],
                        [-0.46612, 51.4840702],
                        [-0.4670378, 51.4836125],
                        [-0.4678149, 51.4834766],
                        [-0.4683947, 51.4834595],
                        [-0.4687016, 51.483516],
                        [-0.4689418, 51.4836156],
                        [-0.4694347, 51.4839767],
                        [-0.4698678, 51.4841541],
                        [-0.4702865, 51.4842105],
                        [-0.4713366, 51.4842715],
                        [-0.4767408, 51.484333],
                        [-0.4786847, 51.4843979],
                        [-0.4858522, 51.4845211],
                        [-0.4924618, 51.4844658],
                        [-0.4929464, 51.4843951],
                        [-0.4932389, 51.4842687],
                        [-0.4941063, 51.4835264],
                        [-0.4942038, 51.4832362],
                        [-0.4940615, 51.482989],
                        [-0.4934819, 51.482455],
                        [-0.4931906, 51.4822993],
                        [-0.4928019, 51.4821946],
                        [-0.4899241, 51.4820696],
                        [-0.4860034, 51.4819803],
                        [-0.4841793, 51.4819797],
                        [-0.4836368, 51.4818583],
                        [-0.4834437, 51.4817542],
                        [-0.4829704, 51.4813801],
                        [-0.4827279, 51.4813249],
                        [-0.4823815, 51.4813033],
                        [-0.4735611, 51.481265],
                        [-0.4660402, 51.4814888],
                        [-0.4647853, 51.481473],
                        [-0.4642911, 51.4814206],
                        [-0.4639652, 51.4813252],
                        [-0.4633274, 51.4810349],
                        [-0.4627608, 51.4807069],
                        [-0.4620765, 51.4802409],
                        [-0.4617915, 51.4799602],
                        [-0.4616007, 51.4796484],
                        [-0.4613933, 51.4791107],
                        [-0.4604916, 51.478087],
                        [-0.4603287, 51.4775686],
                        [-0.460391, 51.4772378],
                        [-0.4605574, 51.4770366],
                        [-0.4612951, 51.4765844],
                        [-0.461503, 51.4763895],
                        [-0.4616134, 51.4756439],
                        [-0.4617708, 51.4752511],
                        [-0.4620293, 51.4750082],
                        [-0.4627163, 51.474642],
                        [-0.4627745, 51.4745008],
                        [-0.4627162, 51.4741865],
                        [-0.4628551, 51.4739587],
                        [-0.463256, 51.4737753],
                        [-0.4646376, 51.4732766],
                        [-0.4670354, 51.4728655],
                        [-0.4677085, 51.4728358]
                    ]
                ]
            ]
        }
    }]
}