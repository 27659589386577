import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useMapContext } from './MapContext';
import { d10_20_poly } from '../areas/Decrease_10-20_Poly';
import { d20_50_poly } from '../areas/Decrease_20-50_Poly';
import { d50_100_poly } from '../areas/Decrease_50-100_Poly';
import { d100_200_poly } from '../areas/Decrease_100-200_Poly';
import { d200_poly } from '../areas/Decrease_200_Poly';
import { i10_20_poly } from '../areas/Increase_10-20_Poly';
import { i20_50_poly } from '../areas/Increase_20-50_Poly';
import { i50_100_poly } from '../areas/Increase_50-100_Poly';
import { i100_200_poly } from '../areas/Increase_100-200_Poly';
import { i200_poly } from '../areas/Increase_200_Poly';

// Fix for default marker icon issue
// delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});

const customIcon = new L.Icon({
  iconUrl: 'path/to/your/icon.png', // Replace with the path to your custom icon
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  shadowUrl: 'path/to/your/icon-shadow.png', // Optional: path to the shadow image
  shadowSize: [41, 41], // Size of the shadow
  shadowAnchor: [12, 41] // Point of the shadow which will correspond to marker's location
});

const LeafletMap = ({showFewer, showGreater, marker}) => {
  const mapRef = useMapContext();
  const [map, setMap] = useState(null);

  useEffect(() => {
    mapRef.current = map;
  }, [mapRef, map]);

  const style_d10_20 = {
    weight: 0,     // Line width
    fillColor: '#00ff00', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_d20_50 = {
    weight: 0,     // Line width
    fillColor: '#00d800', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_d50_100 = {
    weight: 0,     // Line width
    fillColor: '#00b200', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_d100_200 = {
    weight: 0,     // Line width
    fillColor: '#008c00', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_d200 = {
    weight: 0,     // Line width
    fillColor: '#006600', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };

  const style_i10_20 = {
    weight: 0,     // Line width
    fillColor: '#ffd8d8', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_i20_50 = {
    weight: 0,     // Line width
    fillColor: '#ffb2b2', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_i50_100 = {
    weight: 0,     // Line width
    fillColor: '#ff8c8c', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_i100_200 = {
    weight: 0,     // Line width
    fillColor: '#ff6666', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };
  const style_i200 = {
    weight: 0,     // Line width
    fillColor: '#ff3f3f', // Fill color for polygons
    fillOpacity: 0.8  // Fill opacity
  };

  return (
    <MapContainer
      center={[51.47271, -0.53106]}
      zoom={11}
      scrollWheelZoom={false}
      style={{ height: '100vh', width: '100%' }}
      ref={setMap}
    >
      <TileLayer
        url="https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmVjZ3N0dWRpbyIsImEiOiJjbTI5MHU1bWowMGY2MnFxcDR3c3R3eGFhIn0.SVRqdgT6uMftl-WJR5Tikg"
        attribution='<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a class="mapbox-improve-map" href="https://apps.mapbox.com/feedback/?owner=mapbox&id=satellite-streets-v9" target="_blank" rel="noopener">Improve this map</a>'
      />
      <GeoJSON data={d10_20_poly} style={style_d10_20}>
        <Popup>
          Decrease 10 - 20
        </Popup>
      </GeoJSON>
      <GeoJSON data={d20_50_poly} style={style_d20_50}>
        <Popup>
          Decrease 20 - 50
        </Popup>
      </GeoJSON>
      <GeoJSON data={d50_100_poly} style={style_d50_100}>
        <Popup>
          Decrease 50 - 100
        </Popup>
      </GeoJSON>
      <GeoJSON data={d100_200_poly} style={style_d100_200}>
        <Popup>
          Decrease 100 - 200
        </Popup>
      </GeoJSON>
      <GeoJSON data={d200_poly} style={style_d200}>
        <Popup>
          Decrease 200
        </Popup>
      </GeoJSON>

      <GeoJSON data={i10_20_poly} style={style_i10_20}>
        <Popup>
          Increase 10 - 20
        </Popup>
      </GeoJSON>
      <GeoJSON data={i20_50_poly} style={style_i20_50}>
        <Popup>
          Increase 20 - 50
        </Popup>
      </GeoJSON>
      <GeoJSON data={i50_100_poly} style={style_i50_100}>
        <Popup>
          Increase 50 - 100
        </Popup>
      </GeoJSON>
      <GeoJSON data={i100_200_poly} style={style_i100_200}>
        <Popup>
          Increase 100 - 200
        </Popup>
      </GeoJSON>
      <GeoJSON data={i200_poly} style={style_i200}>
        <Popup>
          Increase 200
        </Popup>
      </GeoJSON>
    </MapContainer>
  );
};

export default LeafletMap;
