export const d50_100_poly = {
    "type": "FeatureCollection",
    "name": "Decrease_50-100_Poly",
    "features": [{
        "type": "Feature",
        "properties": {
            "NAME": "-99",
            "LAYER": "Contour Line, Intermediate",
            "ELEVATION": -99,
            "CLOSED_CON": "YES"
        },
        "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    [
                        [-0.3540131, 51.4227791],
                        [-0.3538275, 51.4227995],
                        [-0.3535711, 51.4229688],
                        [-0.3535381, 51.4232154],
                        [-0.3536273, 51.4237559],
                        [-0.3538899, 51.4240746],
                        [-0.354017, 51.4241246],
                        [-0.3542849, 51.4240336],
                        [-0.3544056, 51.4239217],
                        [-0.3544586, 51.4236934],
                        [-0.3543228, 51.4231579],
                        [-0.3540131, 51.4227791]
                    ]
                ],
                [
                    [
                        [-0.3125587, 51.4264978],
                        [-0.3109514, 51.4262002],
                        [-0.309313, 51.4260408],
                        [-0.3073889, 51.425705],
                        [-0.3060841, 51.4255631],
                        [-0.3021637, 51.4254579],
                        [-0.3003416, 51.4254645],
                        [-0.2993691, 51.4253241],
                        [-0.2984655, 51.4250995],
                        [-0.2978266, 51.4250008],
                        [-0.2974618, 51.4250021],
                        [-0.2967455, 51.4251012],
                        [-0.2968758, 51.425225],
                        [-0.2970515, 51.4255427],
                        [-0.2975279, 51.4258064],
                        [-0.2989425, 51.4260576],
                        [-0.3007021, 51.4262664],
                        [-0.3012008, 51.4263956],
                        [-0.3020786, 51.4267514],
                        [-0.3024458, 51.4268498],
                        [-0.3032262, 51.4270196],
                        [-0.3049893, 51.4272939],
                        [-0.3053669, 51.4274311],
                        [-0.3060561, 51.4277626],
                        [-0.3072364, 51.4280769],
                        [-0.3080825, 51.4283575],
                        [-0.3092446, 51.4286375],
                        [-0.310559, 51.4288643],
                        [-0.3122289, 51.4292687],
                        [-0.3132049, 51.4296364],
                        [-0.3146965, 51.4300081],
                        [-0.3160502, 51.4305735],
                        [-0.3191939, 51.4313528],
                        [-0.3205809, 51.4318697],
                        [-0.321947, 51.4322405],
                        [-0.3230557, 51.432685],
                        [-0.3243463, 51.4330091],
                        [-0.3254321, 51.4334457],
                        [-0.3259668, 51.4335977],
                        [-0.3268089, 51.4337531],
                        [-0.3272947, 51.4338862],
                        [-0.3282097, 51.4343262],
                        [-0.3288273, 51.4345296],
                        [-0.3291797, 51.4346052],
                        [-0.3303259, 51.4347488],
                        [-0.3319531, 51.4350708],
                        [-0.3324622, 51.4352201],
                        [-0.3334494, 51.4355864],
                        [-0.3348291, 51.4358754],
                        [-0.336287, 51.4365355],
                        [-0.3376747, 51.4368156],
                        [-0.3391506, 51.4374532],
                        [-0.3402822, 51.4376532],
                        [-0.3406875, 51.4377739],
                        [-0.3419808, 51.4384077],
                        [-0.3433795, 51.4386674],
                        [-0.3437451, 51.438799],
                        [-0.3447351, 51.4393002],
                        [-0.34625, 51.4395853],
                        [-0.3466079, 51.4397275],
                        [-0.3476039, 51.4402419],
                        [-0.3486839, 51.4404616],
                        [-0.3490442, 51.4405686],
                        [-0.3501489, 51.4411327],
                        [-0.3518358, 51.4415666],
                        [-0.3528885, 51.4421241],
                        [-0.3545477, 51.4426794],
                        [-0.3555524, 51.4432703],
                        [-0.3571588, 51.4438741],
                        [-0.357949, 51.4443837],
                        [-0.3589105, 51.4448099],
                        [-0.3593004, 51.4451158],
                        [-0.3599054, 51.4457532],
                        [-0.3609012, 51.4464727],
                        [-0.361745, 51.4475117],
                        [-0.3642254, 51.4488944],
                        [-0.3645866, 51.4489011],
                        [-0.3648963, 51.4487688],
                        [-0.3656749, 51.4478902],
                        [-0.3662631, 51.4474485],
                        [-0.3668051, 51.4472029],
                        [-0.3671772, 51.4470956],
                        [-0.3675697, 51.4470544],
                        [-0.367746, 51.4470797],
                        [-0.368033, 51.4471681],
                        [-0.3689738, 51.4475741],
                        [-0.3703849, 51.4479485],
                        [-0.3716216, 51.4484179],
                        [-0.3732197, 51.4487735],
                        [-0.3762182, 51.4500623],
                        [-0.3782088, 51.4506334],
                        [-0.3792844, 51.4510878],
                        [-0.3804381, 51.4514842],
                        [-0.3824622, 51.4522689],
                        [-0.3835791, 51.4528261],
                        [-0.3846535, 51.453136],
                        [-0.3850809, 51.4533413],
                        [-0.3861705, 51.4540151],
                        [-0.3875493, 51.4546263],
                        [-0.3879626, 51.4549102],
                        [-0.3882173, 51.4551966],
                        [-0.3885058, 51.4556674],
                        [-0.3886299, 51.4560722],
                        [-0.3886917, 51.456504],
                        [-0.3886661, 51.4567703],
                        [-0.3881142, 51.457546],
                        [-0.3877588, 51.4578049],
                        [-0.3858531, 51.4588806],
                        [-0.3842887, 51.4596657],
                        [-0.3817965, 51.4615242],
                        [-0.3797102, 51.4628211],
                        [-0.3785146, 51.4640305],
                        [-0.378353, 51.4642723],
                        [-0.3782602, 51.4645743],
                        [-0.3783164, 51.4648942],
                        [-0.3787594, 51.4656178],
                        [-0.379753, 51.4667831],
                        [-0.3805328, 51.4674856],
                        [-0.3810088, 51.4677642],
                        [-0.3828498, 51.4686422],
                        [-0.3840002, 51.4692799],
                        [-0.3862135, 51.4702379],
                        [-0.3869139, 51.4704805],
                        [-0.387976, 51.470725],
                        [-0.3884543, 51.4708006],
                        [-0.3908985, 51.4709085],
                        [-0.3914843, 51.4708777],
                        [-0.3935269, 51.4706153],
                        [-0.3940719, 51.4705177],
                        [-0.39552, 51.4701362],
                        [-0.3969502, 51.4699583],
                        [-0.3995549, 51.4692628],
                        [-0.4015242, 51.4688713],
                        [-0.4031136, 51.468342],
                        [-0.408543, 51.4668624],
                        [-0.4099841, 51.4666404],
                        [-0.4114518, 51.4664983],
                        [-0.412911, 51.4664039],
                        [-0.4143488, 51.466207],
                        [-0.4162242, 51.4661212],
                        [-0.4185882, 51.4661609],
                        [-0.4203265, 51.4662447],
                        [-0.4226912, 51.4664164],
                        [-0.4249009, 51.4666292],
                        [-0.4291006, 51.4668301],
                        [-0.4314885, 51.4670336],
                        [-0.4331017, 51.4672696],
                        [-0.4373312, 51.4675187],
                        [-0.4403956, 51.4678959],
                        [-0.4418352, 51.4681311],
                        [-0.4444692, 51.4684733],
                        [-0.4459805, 51.4685892],
                        [-0.4471271, 51.4687441],
                        [-0.4479078, 51.4689102],
                        [-0.44893, 51.469247],
                        [-0.4493599, 51.4693445],
                        [-0.4504891, 51.4695251],
                        [-0.4531355, 51.4697476],
                        [-0.4536517, 51.4698644],
                        [-0.4548373, 51.4702499],
                        [-0.4555839, 51.47038],
                        [-0.4564229, 51.4704442],
                        [-0.4587474, 51.4704979],
                        [-0.4601375, 51.4705911],
                        [-0.4617429, 51.4706107],
                        [-0.4631121, 51.4707356],
                        [-0.4645602, 51.470733],
                        [-0.4658175, 51.4708295],
                        [-0.4665683, 51.4709415],
                        [-0.4677381, 51.4713289],
                        [-0.4686375, 51.471496],
                        [-0.4692279, 51.4714579],
                        [-0.4701852, 51.4711992],
                        [-0.4707589, 51.4711292],
                        [-0.4744077, 51.4711186],
                        [-0.4760378, 51.4711876],
                        [-0.4771356, 51.4711945],
                        [-0.4789608, 51.4711268],
                        [-0.484425, 51.4711027],
                        [-0.4865304, 51.4709924],
                        [-0.4901525, 51.4710059],
                        [-0.4907366, 51.4709472],
                        [-0.4910038, 51.4708825],
                        [-0.4914308, 51.4707155],
                        [-0.4916352, 51.470535],
                        [-0.4915634, 51.4699948],
                        [-0.4913518, 51.4696188],
                        [-0.4906613, 51.4689351],
                        [-0.4905026, 51.4688429],
                        [-0.4902137, 51.4687737],
                        [-0.4824556, 51.4687024],
                        [-0.4819926, 51.468625],
                        [-0.4815012, 51.4683773],
                        [-0.4810493, 51.4682189],
                        [-0.4789223, 51.467935],
                        [-0.4761913, 51.4679394],
                        [-0.4733501, 51.4678471],
                        [-0.4651514, 51.4678573],
                        [-0.4622961, 51.4677982],
                        [-0.4617988, 51.4677494],
                        [-0.4614514, 51.4676214],
                        [-0.4610445, 51.4672048],
                        [-0.4603444, 51.4668794],
                        [-0.4601115, 51.4666154],
                        [-0.4600786, 51.4662936],
                        [-0.4602276, 51.4635829],
                        [-0.4603017, 51.4632431],
                        [-0.4605553, 51.4630053],
                        [-0.4612425, 51.462723],
                        [-0.4618752, 51.4622947],
                        [-0.4621151, 51.4622123],
                        [-0.4623033, 51.4621967],
                        [-0.4650319, 51.4621833],
                        [-0.4800252, 51.4622982],
                        [-0.480721, 51.4622609],
                        [-0.4812415, 51.4621423],
                        [-0.4815245, 51.4620107],
                        [-0.4820118, 51.4616345],
                        [-0.4821649, 51.4615685],
                        [-0.482514, 51.4615108],
                        [-0.4889068, 51.4614931],
                        [-0.4893819, 51.4614708],
                        [-0.4897164, 51.4614084],
                        [-0.4902213, 51.4612078],
                        [-0.4909954, 51.4605777],
                        [-0.4929503, 51.459303],
                        [-0.4932409, 51.4590427],
                        [-0.4933626, 51.4587852],
                        [-0.4932197, 51.4581099],
                        [-0.4932389, 51.4579198],
                        [-0.4935388, 51.4573027],
                        [-0.4935385, 51.4571642],
                        [-0.4933951, 51.4569806],
                        [-0.493108, 51.4567997],
                        [-0.4924914, 51.4565856],
                        [-0.4915398, 51.4564088],
                        [-0.490883, 51.456336],
                        [-0.4885147, 51.4563133],
                        [-0.487014, 51.4563906],
                        [-0.486674, 51.4563609],
                        [-0.4854027, 51.4561385],
                        [-0.4837868, 51.4561111],
                        [-0.4822274, 51.4562438],
                        [-0.4808869, 51.4563154],
                        [-0.4793227, 51.4566531],
                        [-0.4781747, 51.4567261],
                        [-0.4767982, 51.4568685],
                        [-0.4737328, 51.4570298],
                        [-0.469376, 51.4571641],
                        [-0.4686178, 51.4572843],
                        [-0.4677696, 51.4575704],
                        [-0.467432, 51.4576156],
                        [-0.4661109, 51.4576737],
                        [-0.4650423, 51.4576431],
                        [-0.4640207, 51.4575668],
                        [-0.4637863, 51.4575219],
                        [-0.4630933, 51.4571605],
                        [-0.4621115, 51.4568834],
                        [-0.4615968, 51.4565238],
                        [-0.46118, 51.4563452],
                        [-0.460612, 51.4562206],
                        [-0.4593104, 51.4560397],
                        [-0.4587441, 51.4559104],
                        [-0.4576451, 51.4553822],
                        [-0.4561172, 51.4550734],
                        [-0.4556195, 51.4549371],
                        [-0.4545054, 51.4544027],
                        [-0.452558, 51.4540113],
                        [-0.4518074, 51.4535788],
                        [-0.4513406, 51.4534116],
                        [-0.4505261, 51.4533157],
                        [-0.4480087, 51.4532478],
                        [-0.446853, 51.4531091],
                        [-0.446484, 51.4530266],
                        [-0.4458149, 51.4525498],
                        [-0.4452874, 51.4523972],
                        [-0.4446133, 51.4523447],
                        [-0.4410359, 51.4522773],
                        [-0.438244, 51.4521105],
                        [-0.4378429, 51.4520338],
                        [-0.437065, 51.4515373],
                        [-0.4366509, 51.4514221],
                        [-0.436322, 51.4513842],
                        [-0.4267523, 51.4510783],
                        [-0.4219328, 51.4509798],
                        [-0.4200332, 51.4508875],
                        [-0.4176617, 51.4506607],
                        [-0.416469, 51.4503937],
                        [-0.4149571, 51.4501858],
                        [-0.4135331, 51.4498971],
                        [-0.4105839, 51.4491965],
                        [-0.4096986, 51.4489502],
                        [-0.4091406, 51.4487436],
                        [-0.4081016, 51.4482653],
                        [-0.4067725, 51.4477521],
                        [-0.4061024, 51.4475445],
                        [-0.405112, 51.4473076],
                        [-0.4037092, 51.4471383],
                        [-0.4021355, 51.4467945],
                        [-0.3997336, 51.4461156],
                        [-0.3990754, 51.445866],
                        [-0.3979098, 51.4452171],
                        [-0.3972746, 51.4449556],
                        [-0.3950083, 51.4442527],
                        [-0.3940747, 51.4439044],
                        [-0.3927776, 51.4435661],
                        [-0.3920607, 51.4433367],
                        [-0.3915051, 51.4431037],
                        [-0.3905346, 51.4426116],
                        [-0.389241, 51.4423861],
                        [-0.3882648, 51.4418794],
                        [-0.3867451, 51.4412364],
                        [-0.384937, 51.4407813],
                        [-0.3835212, 51.4402319],
                        [-0.3820237, 51.4397887],
                        [-0.3807597, 51.4393269],
                        [-0.3790148, 51.4389134],
                        [-0.3778166, 51.4385872],
                        [-0.3761936, 51.4379611],
                        [-0.375188, 51.4373839],
                        [-0.3747405, 51.4372106],
                        [-0.3727438, 51.4367468],
                        [-0.3708786, 51.4360711],
                        [-0.3693246, 51.4358165],
                        [-0.3653473, 51.4346626],
                        [-0.3636601, 51.4343724],
                        [-0.3626443, 51.4339793],
                        [-0.3621005, 51.4336603],
                        [-0.3602729, 51.4321164],
                        [-0.3594728, 51.4311561],
                        [-0.3584517, 51.4300999],
                        [-0.3575127, 51.4292404],
                        [-0.356723, 51.4282689],
                        [-0.3559696, 51.4277568],
                        [-0.3557418, 51.4274316],
                        [-0.3554388, 51.4266261],
                        [-0.3553081, 51.4264466],
                        [-0.3552011, 51.4264302],
                        [-0.3550267, 51.4264642],
                        [-0.3548511, 51.4266828],
                        [-0.3548696, 51.427566],
                        [-0.3545989, 51.4292155],
                        [-0.3545665, 51.4303369],
                        [-0.3544493, 51.4310185],
                        [-0.3543395, 51.431176],
                        [-0.3540513, 51.4314213],
                        [-0.3536626, 51.4316334],
                        [-0.35331, 51.4317374],
                        [-0.3524909, 51.4317889],
                        [-0.3519673, 51.4317575],
                        [-0.3506678, 51.4315674],
                        [-0.3494364, 51.4314614],
                        [-0.3479643, 51.4312259],
                        [-0.3465064, 51.4311102],
                        [-0.3451135, 51.4308043],
                        [-0.3436322, 51.4306448],
                        [-0.3421939, 51.4304132],
                        [-0.3408754, 51.430251],
                        [-0.3393305, 51.429923],
                        [-0.3379264, 51.4297408],
                        [-0.3364235, 51.429469],
                        [-0.3347436, 51.4292347],
                        [-0.333231, 51.4289572],
                        [-0.327886, 51.4281911],
                        [-0.3265545, 51.4280353],
                        [-0.324921, 51.4277492],
                        [-0.3222115, 51.4273504],
                        [-0.3182384, 51.4269028],
                        [-0.3157591, 51.4268093],
                        [-0.3125587, 51.4264978]
                    ],
                    [
                        [-0.4807087, 51.4684055],
                        [-0.4810813, 51.4685382],
                        [-0.4817334, 51.468912],
                        [-0.4821168, 51.4690137],
                        [-0.4848388, 51.4691012],
                        [-0.4882753, 51.4691458],
                        [-0.4893271, 51.4691877],
                        [-0.4901619, 51.4692676],
                        [-0.490581, 51.4694181],
                        [-0.4909252, 51.4697567],
                        [-0.4909729, 51.4700528],
                        [-0.4909035, 51.4704528],
                        [-0.4905135, 51.470692],
                        [-0.4898165, 51.4707519],
                        [-0.4865435, 51.4707594],
                        [-0.4840668, 51.4708831],
                        [-0.4807443, 51.4709603],
                        [-0.4789444, 51.470937],
                        [-0.476517, 51.4709867],
                        [-0.4743866, 51.470922],
                        [-0.4707398, 51.4709409],
                        [-0.4703563, 51.4709675],
                        [-0.4699715, 51.4710434],
                        [-0.4691213, 51.4713274],
                        [-0.4685981, 51.4713416],
                        [-0.4668109, 51.4708392],
                        [-0.4652876, 51.4706027],
                        [-0.4641033, 51.470197],
                        [-0.463882, 51.4701092],
                        [-0.4637472, 51.4700034],
                        [-0.4634536, 51.4694625],
                        [-0.4632264, 51.4692922],
                        [-0.4629072, 51.4691882],
                        [-0.4613824, 51.4689801],
                        [-0.4610167, 51.4687846],
                        [-0.460908, 51.4685042],
                        [-0.4609784, 51.4682693],
                        [-0.4611099, 51.468157],
                        [-0.4612652, 51.4680956],
                        [-0.4615414, 51.4680615],
                        [-0.464943, 51.4681371],
                        [-0.4730776, 51.4681669],
                        [-0.4762859, 51.468239],
                        [-0.4788318, 51.4682438],
                        [-0.4801209, 51.4683184],
                        [-0.4807087, 51.4684055]
                    ],
                    [
                        [-0.3998192, 51.4487844],
                        [-0.4005484, 51.448728],
                        [-0.4011661, 51.4488179],
                        [-0.4028117, 51.4493003],
                        [-0.4031123, 51.4493555],
                        [-0.4033853, 51.4493235],
                        [-0.4041283, 51.4489647],
                        [-0.4045105, 51.4488379],
                        [-0.4048763, 51.4487649],
                        [-0.4056287, 51.4487299],
                        [-0.4063216, 51.448778],
                        [-0.4067298, 51.4488791],
                        [-0.4097841, 51.4498934],
                        [-0.410546, 51.4500974],
                        [-0.4125216, 51.4504863],
                        [-0.4148038, 51.4510354],
                        [-0.4190389, 51.4516087],
                        [-0.4221144, 51.4516035],
                        [-0.4238079, 51.4515644],
                        [-0.4274267, 51.4515803],
                        [-0.4329953, 51.4516496],
                        [-0.4360397, 51.4517244],
                        [-0.4365594, 51.451796],
                        [-0.4375415, 51.4523194],
                        [-0.4381047, 51.4524643],
                        [-0.4414724, 51.4525929],
                        [-0.4448773, 51.4526611],
                        [-0.4453774, 51.4527865],
                        [-0.4461395, 51.4532365],
                        [-0.4466454, 51.4533674],
                        [-0.4479725, 51.4535208],
                        [-0.4504431, 51.4535954],
                        [-0.4509443, 51.4536613],
                        [-0.4512804, 51.4537825],
                        [-0.451836, 51.4541187],
                        [-0.4521438, 51.454254],
                        [-0.4539594, 51.4546073],
                        [-0.4551638, 51.4551836],
                        [-0.4569831, 51.4555601],
                        [-0.4577821, 51.4559451],
                        [-0.4582522, 51.4561076],
                        [-0.4597606, 51.4563771],
                        [-0.4603353, 51.4565303],
                        [-0.4608529, 51.4567507],
                        [-0.4621333, 51.4574213],
                        [-0.4628794, 51.4577573],
                        [-0.4635509, 51.457919],
                        [-0.465183, 51.4581269],
                        [-0.4662709, 51.4583522],
                        [-0.4666245, 51.4583482],
                        [-0.4675146, 51.4581592],
                        [-0.4693671, 51.4578989],
                        [-0.4733022, 51.4577676],
                        [-0.4752259, 51.4576279],
                        [-0.4819669, 51.4573307],
                        [-0.4826304, 51.4571439],
                        [-0.4831719, 51.4568623],
                        [-0.4836219, 51.4567051],
                        [-0.4838864, 51.4566594],
                        [-0.4848051, 51.4566459],
                        [-0.4852465, 51.456677],
                        [-0.4856005, 51.4567711],
                        [-0.4859164, 51.4569269],
                        [-0.4862641, 51.4571773],
                        [-0.4867477, 51.4573415],
                        [-0.4872856, 51.4573729],
                        [-0.4903605, 51.4573655],
                        [-0.4908901, 51.4573949],
                        [-0.4912574, 51.4574577],
                        [-0.4916189, 51.4575898],
                        [-0.4918643, 51.4577725],
                        [-0.4922793, 51.458312],
                        [-0.4926814, 51.4587232],
                        [-0.4927448, 51.4589537],
                        [-0.4926364, 51.4591316],
                        [-0.4923486, 51.4593552],
                        [-0.4908803, 51.4602757],
                        [-0.4897196, 51.4608927],
                        [-0.4892599, 51.461042],
                        [-0.4864606, 51.4611537],
                        [-0.4824178, 51.4612025],
                        [-0.4818228, 51.4613182],
                        [-0.4807027, 51.4618439],
                        [-0.4801714, 51.4619176],
                        [-0.4793902, 51.4619662],
                        [-0.4627204, 51.4619109],
                        [-0.4621463, 51.4619305],
                        [-0.4616413, 51.4620118],
                        [-0.461128, 51.4622337],
                        [-0.4601578, 51.4627533],
                        [-0.4597413, 51.4630966],
                        [-0.4596485, 51.4633156],
                        [-0.4595528, 51.4655692],
                        [-0.4595912, 51.4666481],
                        [-0.4598916, 51.4670215],
                        [-0.4605668, 51.4674947],
                        [-0.4606775, 51.4676803],
                        [-0.4606657, 51.4677914],
                        [-0.4605827, 51.4678709],
                        [-0.4602025, 51.4680333],
                        [-0.4587418, 51.4683498],
                        [-0.458326, 51.4684806],
                        [-0.4580929, 51.468657],
                        [-0.4578307, 51.4691515],
                        [-0.4574344, 51.4693721],
                        [-0.4570388, 51.4693044],
                        [-0.4555894, 51.46885],
                        [-0.4544983, 51.4686275],
                        [-0.4531599, 51.4682837],
                        [-0.4502721, 51.4677188],
                        [-0.4497513, 51.4675823],
                        [-0.4487141, 51.4672222],
                        [-0.4474942, 51.4670325],
                        [-0.4465469, 51.4667534],
                        [-0.4459942, 51.4666552],
                        [-0.4448002, 51.4665625],
                        [-0.4403569, 51.4664158],
                        [-0.439337, 51.4663407],
                        [-0.4386882, 51.4662539],
                        [-0.4382163, 51.4661427],
                        [-0.4379489, 51.4660476],
                        [-0.4373098, 51.4657106],
                        [-0.4359641, 51.4656017],
                        [-0.4348155, 51.4654521],
                        [-0.4244483, 51.4652379],
                        [-0.4229681, 51.4652738],
                        [-0.4213696, 51.4654294],
                        [-0.4144182, 51.4654672],
                        [-0.4114756, 51.4655193],
                        [-0.4104155, 51.4655921],
                        [-0.408411, 51.4659112],
                        [-0.4060596, 51.4661877],
                        [-0.4046341, 51.4664316],
                        [-0.4026501, 51.4669124],
                        [-0.399757, 51.4673299],
                        [-0.3966421, 51.4680867],
                        [-0.3955549, 51.4683],
                        [-0.3941491, 51.4685067],
                        [-0.3926374, 51.4688881],
                        [-0.3912473, 51.4691412],
                        [-0.3899762, 51.4694253],
                        [-0.3893968, 51.4694694],
                        [-0.3885123, 51.4694407],
                        [-0.3875165, 51.469307],
                        [-0.3869295, 51.4691704],
                        [-0.3844222, 51.4681494],
                        [-0.3827889, 51.4672454],
                        [-0.3819729, 51.4666301],
                        [-0.3816427, 51.4662783],
                        [-0.3812565, 51.465618],
                        [-0.3812333, 51.4650276],
                        [-0.3812721, 51.4648143],
                        [-0.38137, 51.4646603],
                        [-0.3829207, 51.4631668],
                        [-0.3845144, 51.462165],
                        [-0.3856205, 51.4612373],
                        [-0.3873339, 51.4603102],
                        [-0.3884485, 51.4598669],
                        [-0.3894577, 51.4593968],
                        [-0.3907459, 51.4586154],
                        [-0.391797, 51.4580984],
                        [-0.3922439, 51.4577758],
                        [-0.3931911, 51.4567249],
                        [-0.3944087, 51.4557993],
                        [-0.3950964, 51.4550023],
                        [-0.3957255, 51.4541461],
                        [-0.3968201, 51.4531754],
                        [-0.3972314, 51.4521247],
                        [-0.3975118, 51.4516983],
                        [-0.3983795, 51.4507897],
                        [-0.398874, 51.4494828],
                        [-0.3990386, 51.4492069],
                        [-0.3992601, 51.4490137],
                        [-0.3998192, 51.4487844]
                    ],
                    [
                        [-0.3593491, 51.4336489],
                        [-0.3598365, 51.4338961],
                        [-0.3609458, 51.4345654],
                        [-0.3619637, 51.4354731],
                        [-0.362439, 51.4358927],
                        [-0.3629027, 51.4364435],
                        [-0.3634081, 51.4376146],
                        [-0.3638907, 51.4384072],
                        [-0.3639968, 51.4393283],
                        [-0.3644225, 51.4407578],
                        [-0.3647137, 51.4425618],
                        [-0.3646816, 51.4431343],
                        [-0.3641707, 51.4447309],
                        [-0.3640212, 51.4449439],
                        [-0.3637789, 51.4451508],
                        [-0.3633057, 51.4453848],
                        [-0.3628956, 51.4452867],
                        [-0.3618467, 51.4448141],
                        [-0.360922, 51.4439184],
                        [-0.3594017, 51.442747],
                        [-0.358753, 51.4420039],
                        [-0.3581917, 51.4412666],
                        [-0.357647, 51.4403544],
                        [-0.3569981, 51.4394557],
                        [-0.3565098, 51.438207],
                        [-0.3559729, 51.4374331],
                        [-0.3557949, 51.4370849],
                        [-0.3552701, 51.4357924],
                        [-0.3551449, 51.4353488],
                        [-0.3550503, 51.4346439],
                        [-0.3550775, 51.4341914],
                        [-0.3551544, 51.4339491],
                        [-0.3552531, 51.4338134],
                        [-0.3555889, 51.4335823],
                        [-0.3563047, 51.4333036],
                        [-0.3572187, 51.4331527],
                        [-0.3576913, 51.4331334],
                        [-0.3579701, 51.4331604],
                        [-0.3593491, 51.4336489]
                    ]
                ],
                [
                    [
                        [-0.6353294, 51.4710647],
                        [-0.6332719, 51.4709336],
                        [-0.6287428, 51.4709035],
                        [-0.6218966, 51.4709566],
                        [-0.6099614, 51.4710023],
                        [-0.6063849, 51.470928],
                        [-0.60249, 51.4707876],
                        [-0.601016, 51.4708214],
                        [-0.59794, 51.4710227],
                        [-0.5938696, 51.471092],
                        [-0.5723947, 51.4711474],
                        [-0.5335213, 51.4713334],
                        [-0.5291551, 51.4713266],
                        [-0.5280448, 51.4713505],
                        [-0.5268322, 51.471504],
                        [-0.5262532, 51.4715326],
                        [-0.5250269, 51.4714875],
                        [-0.5235019, 51.4713663],
                        [-0.522435, 51.4713426],
                        [-0.5109564, 51.4713648],
                        [-0.5092557, 51.4714181],
                        [-0.5086464, 51.471515],
                        [-0.507709, 51.4717595],
                        [-0.5073527, 51.4718037],
                        [-0.5046669, 51.4717752],
                        [-0.5005322, 51.4718603],
                        [-0.4991315, 51.471783],
                        [-0.4980212, 51.4718149],
                        [-0.4968235, 51.4719233],
                        [-0.4962437, 51.4720646],
                        [-0.4958103, 51.4723441],
                        [-0.4950301, 51.4730903],
                        [-0.4940725, 51.4738801],
                        [-0.4935834, 51.4745471],
                        [-0.4932866, 51.4752379],
                        [-0.4930583, 51.4754585],
                        [-0.492458, 51.4758908],
                        [-0.4921452, 51.4763441],
                        [-0.4919641, 51.4769961],
                        [-0.4919951, 51.4777758],
                        [-0.4921576, 51.4784987],
                        [-0.4923827, 51.4789435],
                        [-0.4925937, 51.4791391],
                        [-0.49316, 51.4794571],
                        [-0.4934456, 51.4797052],
                        [-0.4938306, 51.4804573],
                        [-0.4946229, 51.4812062],
                        [-0.4955409, 51.4825351],
                        [-0.4957667, 51.4827623],
                        [-0.4960175, 51.4829143],
                        [-0.4964026, 51.4830219],
                        [-0.497395, 51.4831574],
                        [-0.5011396, 51.4834141],
                        [-0.5031756, 51.4838295],
                        [-0.5055646, 51.4840128],
                        [-0.5066166, 51.4842786],
                        [-0.5070888, 51.4843608],
                        [-0.5101894, 51.4845854],
                        [-0.5119666, 51.4846392],
                        [-0.5186678, 51.4846592],
                        [-0.5213186, 51.4847692],
                        [-0.5218867, 51.4848135],
                        [-0.5231112, 51.4850197],
                        [-0.5248398, 51.4851136],
                        [-0.5261072, 51.4853353],
                        [-0.5287082, 51.4854754],
                        [-0.5331354, 51.485423],
                        [-0.535636, 51.4854731],
                        [-0.5376629, 51.4854125],
                        [-0.5401798, 51.4854477],
                        [-0.5431036, 51.4854395],
                        [-0.5450641, 51.4855065],
                        [-0.5492025, 51.4855363],
                        [-0.5559568, 51.4859261],
                        [-0.5606064, 51.4860297],
                        [-0.5763303, 51.4861704],
                        [-0.5814952, 51.486267],
                        [-0.5849911, 51.486248],
                        [-0.5876437, 51.4862975],
                        [-0.5927507, 51.486289],
                        [-0.5968525, 51.4864125],
                        [-0.6097229, 51.4864357],
                        [-0.6250389, 51.4865848],
                        [-0.6288374, 51.4865594],
                        [-0.6395758, 51.4863726],
                        [-0.6415368, 51.4862157],
                        [-0.6468515, 51.4859435],
                        [-0.6545907, 51.4858],
                        [-0.6600268, 51.4855937],
                        [-0.6639408, 51.4854884],
                        [-0.6821569, 51.4846994],
                        [-0.6973962, 51.4844088],
                        [-0.7005491, 51.4842308],
                        [-0.7049098, 51.4840568],
                        [-0.7078959, 51.4839806],
                        [-0.7109538, 51.4837951],
                        [-0.7193168, 51.4835847],
                        [-0.724785, 51.4833576],
                        [-0.7291303, 51.4831362],
                        [-0.734692, 51.4827938],
                        [-0.7391183, 51.4824839],
                        [-0.7421651, 51.482217],
                        [-0.743481, 51.4820278],
                        [-0.7455993, 51.4817919],
                        [-0.747242, 51.4814165],
                        [-0.7485163, 51.4811684],
                        [-0.7493931, 51.4809519],
                        [-0.7508485, 51.4805191],
                        [-0.752076, 51.4802712],
                        [-0.7535985, 51.4799071],
                        [-0.7550467, 51.4796961],
                        [-0.7555614, 51.4795554],
                        [-0.7559714, 51.4793701],
                        [-0.7566534, 51.4789726],
                        [-0.7570146, 51.4788512],
                        [-0.7580981, 51.4785881],
                        [-0.7586776, 51.4783487],
                        [-0.7590137, 51.4780763],
                        [-0.7592859, 51.4776752],
                        [-0.7593656, 51.4774531],
                        [-0.7592326, 51.4772066],
                        [-0.7585954, 51.4767065],
                        [-0.7582456, 51.476483],
                        [-0.7577365, 51.4762534],
                        [-0.7565539, 51.475844],
                        [-0.7543773, 51.4755069],
                        [-0.7536111, 51.4753142],
                        [-0.7529208, 51.4750638],
                        [-0.7519295, 51.4745294],
                        [-0.7508068, 51.4741358],
                        [-0.7501154, 51.4739784],
                        [-0.7485968, 51.4737511],
                        [-0.7460249, 51.4734707],
                        [-0.7448994, 51.4733892],
                        [-0.7395879, 51.4732046],
                        [-0.7377052, 51.4730912],
                        [-0.7352315, 51.4729956],
                        [-0.723351, 51.472875],
                        [-0.7178946, 51.4726894],
                        [-0.7147541, 51.4725046],
                        [-0.7090421, 51.4723866],
                        [-0.7039341, 51.4722075],
                        [-0.6993864, 51.472],
                        [-0.6951166, 51.4719026],
                        [-0.6908302, 51.4717536],
                        [-0.687968, 51.4715537],
                        [-0.6863583, 51.4714965],
                        [-0.6847597, 51.4714905],
                        [-0.6758878, 51.4716483],
                        [-0.6673545, 51.4716452],
                        [-0.6635294, 51.4716869],
                        [-0.6627716, 51.4716569],
                        [-0.6594677, 51.4713104],
                        [-0.6585954, 51.4712668],
                        [-0.6557078, 51.4712468],
                        [-0.6506089, 51.4712782],
                        [-0.6433648, 51.4714471],
                        [-0.6391611, 51.4714792],
                        [-0.6374417, 51.4713837],
                        [-0.6364862, 51.4712128],
                        [-0.6353294, 51.4710647]
                    ],
                    [
                        [-0.6325252, 51.4711705],
                        [-0.6333977, 51.4712071],
                        [-0.6344069, 51.4713047],
                        [-0.6359484, 51.471579],
                        [-0.6373056, 51.471759],
                        [-0.6390672, 51.4718322],
                        [-0.643415, 51.4718372],
                        [-0.6556779, 51.4717209],
                        [-0.6587228, 51.4717616],
                        [-0.6621468, 51.4722388],
                        [-0.6631922, 51.4723018],
                        [-0.6657082, 51.4723339],
                        [-0.6664748, 51.4723799],
                        [-0.6673715, 51.4725035],
                        [-0.6692988, 51.4728724],
                        [-0.670405, 51.4730044],
                        [-0.6719736, 51.473067],
                        [-0.6755557, 51.4731248],
                        [-0.6774965, 51.4732193],
                        [-0.6791911, 51.4733492],
                        [-0.6815787, 51.4736675],
                        [-0.6823262, 51.4737128],
                        [-0.6861332, 51.473754],
                        [-0.6880357, 51.473851],
                        [-0.6894653, 51.4738813],
                        [-0.6962742, 51.4737834],
                        [-0.7069498, 51.4738234],
                        [-0.7149827, 51.4739984],
                        [-0.7195973, 51.4743105],
                        [-0.7218353, 51.4747968],
                        [-0.7241903, 51.4750445],
                        [-0.7259845, 51.4752898],
                        [-0.7264913, 51.4754189],
                        [-0.7275406, 51.4757821],
                        [-0.728996, 51.4761112],
                        [-0.7292454, 51.4762218],
                        [-0.7293549, 51.4764219],
                        [-0.7291396, 51.4767813],
                        [-0.7289389, 51.4769929],
                        [-0.7284019, 51.4772633],
                        [-0.7279106, 51.4774048],
                        [-0.7264514, 51.4777006],
                        [-0.7249864, 51.4782001],
                        [-0.7235711, 51.478451],
                        [-0.7221175, 51.4786492],
                        [-0.7191751, 51.479232],
                        [-0.7177441, 51.4794646],
                        [-0.7152672, 51.479719],
                        [-0.7130704, 51.4798771],
                        [-0.7116015, 51.4800669],
                        [-0.7102958, 51.4802929],
                        [-0.7083728, 51.4804963],
                        [-0.7054658, 51.4806817],
                        [-0.7031382, 51.4807654],
                        [-0.7008389, 51.4808978],
                        [-0.6986283, 51.4811609],
                        [-0.694901, 51.4814794],
                        [-0.6904265, 51.4816246],
                        [-0.6885182, 51.4817392],
                        [-0.6874441, 51.4818382],
                        [-0.6860388, 51.4820939],
                        [-0.6839812, 51.4822404],
                        [-0.6803803, 51.4823418],
                        [-0.6719585, 51.4824201],
                        [-0.6688269, 51.4825178],
                        [-0.6657045, 51.4827471],
                        [-0.6642477, 51.4829322],
                        [-0.6600355, 51.483311],
                        [-0.6556914, 51.4834933],
                        [-0.6372384, 51.4837644],
                        [-0.6342015, 51.4838853],
                        [-0.6326759, 51.4839106],
                        [-0.6295105, 51.4840612],
                        [-0.6098448, 51.4841366],
                        [-0.6083161, 51.4842041],
                        [-0.6055204, 51.4845301],
                        [-0.6027226, 51.4846048],
                        [-0.6008982, 51.4846198],
                        [-0.5894885, 51.4845378],
                        [-0.5840355, 51.4846731],
                        [-0.5818438, 51.484787],
                        [-0.5794273, 51.4848264],
                        [-0.5619914, 51.484842],
                        [-0.5418865, 51.4847324],
                        [-0.5377583, 51.4846663],
                        [-0.5355619, 51.4846764],
                        [-0.5330705, 51.4846165],
                        [-0.5292515, 51.4846301],
                        [-0.5273447, 51.4845077],
                        [-0.5257058, 51.4841845],
                        [-0.5230603, 51.4839874],
                        [-0.5116857, 51.4840914],
                        [-0.510246, 51.4840725],
                        [-0.5074669, 51.4839575],
                        [-0.5069857, 51.4838516],
                        [-0.5061774, 51.4834663],
                        [-0.5057605, 51.4833548],
                        [-0.5032958, 51.4832706],
                        [-0.5007526, 51.4830452],
                        [-0.4976186, 51.4828911],
                        [-0.4969412, 51.4828162],
                        [-0.4962151, 51.4826736],
                        [-0.4958503, 51.4824764],
                        [-0.4956501, 51.4822493],
                        [-0.4948531, 51.4809705],
                        [-0.4941049, 51.4802226],
                        [-0.4936287, 51.4795123],
                        [-0.4926835, 51.478777],
                        [-0.4924336, 51.4783426],
                        [-0.4922867, 51.4777998],
                        [-0.4922818, 51.4770103],
                        [-0.4924628, 51.4765627],
                        [-0.493395, 51.4756794],
                        [-0.4936256, 51.4753746],
                        [-0.4939569, 51.4747836],
                        [-0.4946259, 51.4741229],
                        [-0.4949729, 51.4735539],
                        [-0.4952354, 51.4732252],
                        [-0.4958198, 51.4726471],
                        [-0.4963203, 51.4723329],
                        [-0.4965494, 51.4722626],
                        [-0.4971805, 51.4721745],
                        [-0.4987059, 51.4720882],
                        [-0.5006474, 51.4721355],
                        [-0.5047304, 51.4720626],
                        [-0.5074369, 51.4720621],
                        [-0.5077745, 51.4720285],
                        [-0.5090414, 51.4716336],
                        [-0.5096682, 51.4715701],
                        [-0.5113484, 51.4715369],
                        [-0.5222522, 51.471524],
                        [-0.5235382, 51.4715443],
                        [-0.5250699, 51.4716361],
                        [-0.5263354, 51.4716687],
                        [-0.5280665, 51.4715381],
                        [-0.5288098, 51.4715255],
                        [-0.5336956, 51.4715482],
                        [-0.5444624, 51.4715088],
                        [-0.5724045, 51.4713555],
                        [-0.593845, 51.4713422],
                        [-0.5981019, 51.4712679],
                        [-0.6008396, 51.471037],
                        [-0.6027159, 51.470981],
                        [-0.6041249, 51.4710264],
                        [-0.606188, 51.47115],
                        [-0.610104, 51.4712382],
                        [-0.6287413, 51.4711205],
                        [-0.6325252, 51.4711705]
                    ]
                ],
                [
                    [
                        [-0.373007, 51.4780171],
                        [-0.3723169, 51.4771261],
                        [-0.3717451, 51.4766162],
                        [-0.3712372, 51.4760679],
                        [-0.3708437, 51.4758226],
                        [-0.3697207, 51.4753893],
                        [-0.3688351, 51.4749222],
                        [-0.3685062, 51.4748065],
                        [-0.3679406, 51.4746842],
                        [-0.3665412, 51.4745455],
                        [-0.3652945, 51.4745133],
                        [-0.3639024, 51.4746313],
                        [-0.3624283, 51.4748386],
                        [-0.360912, 51.4752245],
                        [-0.3590981, 51.475853],
                        [-0.3580601, 51.4763492],
                        [-0.3572726, 51.4766732],
                        [-0.3564152, 51.4771318],
                        [-0.3549291, 51.477586],
                        [-0.3545904, 51.4777484],
                        [-0.3538523, 51.4782229],
                        [-0.3523508, 51.4790727],
                        [-0.3501271, 51.4801431],
                        [-0.3492358, 51.4807593],
                        [-0.3485297, 51.4811645],
                        [-0.3465711, 51.482412],
                        [-0.3457381, 51.4828128],
                        [-0.3440958, 51.4837409],
                        [-0.3416819, 51.4852163],
                        [-0.3403596, 51.4857354],
                        [-0.339444, 51.4862703],
                        [-0.3386316, 51.4866807],
                        [-0.3382306, 51.4869436],
                        [-0.3375291, 51.4875793],
                        [-0.3366171, 51.4880888],
                        [-0.3358968, 51.4885722],
                        [-0.3338546, 51.4897401],
                        [-0.3329555, 51.4903164],
                        [-0.3321885, 51.4907362],
                        [-0.3314923, 51.4911973],
                        [-0.3302299, 51.4917539],
                        [-0.3298543, 51.4919583],
                        [-0.3287068, 51.4928516],
                        [-0.326775, 51.4938751],
                        [-0.3255859, 51.494725],
                        [-0.3242758, 51.4954467],
                        [-0.3220732, 51.4967963],
                        [-0.3158812, 51.5007856],
                        [-0.3150522, 51.5013894],
                        [-0.3140764, 51.5019222],
                        [-0.3128379, 51.5027017],
                        [-0.312057, 51.5033488],
                        [-0.3114039, 51.5037855],
                        [-0.3107428, 51.5043477],
                        [-0.3095509, 51.5051675],
                        [-0.3086402, 51.5057316],
                        [-0.3070971, 51.5073002],
                        [-0.3063892, 51.507882],
                        [-0.305915, 51.5084135],
                        [-0.3047996, 51.5094073],
                        [-0.3046127, 51.5097362],
                        [-0.3045193, 51.5103213],
                        [-0.3045563, 51.5106421],
                        [-0.3047081, 51.5110903],
                        [-0.3047041, 51.5113312],
                        [-0.3042837, 51.5119806],
                        [-0.3041985, 51.5122967],
                        [-0.3043094, 51.5126458],
                        [-0.3045245, 51.5128598],
                        [-0.3051413, 51.5131294],
                        [-0.3055471, 51.5132237],
                        [-0.3058984, 51.5132469],
                        [-0.3062956, 51.5131866],
                        [-0.3071707, 51.5128378],
                        [-0.3075617, 51.5127422],
                        [-0.3085743, 51.51267],
                        [-0.3089876, 51.5125994],
                        [-0.3093775, 51.5124645],
                        [-0.3104511, 51.5119796],
                        [-0.3108089, 51.5118761],
                        [-0.3120852, 51.5116313],
                        [-0.3135814, 51.5110423],
                        [-0.3151589, 51.5106598],
                        [-0.3173779, 51.5096767],
                        [-0.3215381, 51.5083368],
                        [-0.322472, 51.5079924],
                        [-0.3235863, 51.5075026],
                        [-0.3241345, 51.5073442],
                        [-0.3250378, 51.5071592],
                        [-0.3254275, 51.5070326],
                        [-0.3272386, 51.5061417],
                        [-0.3292668, 51.5054531],
                        [-0.3308901, 51.5046919],
                        [-0.33232, 51.5043019],
                        [-0.3333302, 51.5036951],
                        [-0.3339449, 51.5033997],
                        [-0.335049, 51.5029274],
                        [-0.3363752, 51.5025151],
                        [-0.3370819, 51.5022176],
                        [-0.3382464, 51.501613],
                        [-0.3393281, 51.5009325],
                        [-0.3419175, 51.4996383],
                        [-0.3434464, 51.4987981],
                        [-0.3441171, 51.4983719],
                        [-0.3455007, 51.4978252],
                        [-0.3467433, 51.4970482],
                        [-0.3482255, 51.4963683],
                        [-0.3493739, 51.4955317],
                        [-0.3501206, 51.4950972],
                        [-0.3511358, 51.494583],
                        [-0.3523972, 51.4940691],
                        [-0.3530031, 51.4937735],
                        [-0.3535633, 51.4934312],
                        [-0.3545765, 51.4927026],
                        [-0.3560195, 51.4920468],
                        [-0.35732, 51.4910558],
                        [-0.3590206, 51.4902537],
                        [-0.3600129, 51.4896038],
                        [-0.3622709, 51.4883083],
                        [-0.3631591, 51.4877308],
                        [-0.3644345, 51.4871296],
                        [-0.3664167, 51.4859777],
                        [-0.3678777, 51.4853241],
                        [-0.3691765, 51.4845046],
                        [-0.3699394, 51.4838937],
                        [-0.370578, 51.4834694],
                        [-0.3711247, 51.4829922],
                        [-0.371791, 51.4824942],
                        [-0.3727492, 51.4813627],
                        [-0.3731127, 51.4805786],
                        [-0.3735142, 51.4800191],
                        [-0.3735718, 51.4797121],
                        [-0.3731513, 51.478325],
                        [-0.373007, 51.4780171]
                    ],
                    [
                        [-0.3663142, 51.4769643],
                        [-0.3666939, 51.4771083],
                        [-0.3674162, 51.4776471],
                        [-0.3682235, 51.4780493],
                        [-0.3684603, 51.4782651],
                        [-0.3685524, 51.4784136],
                        [-0.3687882, 51.4790457],
                        [-0.3688531, 51.4798173],
                        [-0.3687036, 51.4805214],
                        [-0.3685735, 51.4807567],
                        [-0.3678719, 51.4816331],
                        [-0.3666141, 51.4826303],
                        [-0.3650867, 51.483696],
                        [-0.3644015, 51.4840654],
                        [-0.3638006, 51.4844854],
                        [-0.3628963, 51.4850048],
                        [-0.3621974, 51.485502],
                        [-0.3599635, 51.4867923],
                        [-0.3587588, 51.4876134],
                        [-0.3574912, 51.4883854],
                        [-0.3565055, 51.488907],
                        [-0.3556043, 51.4894834],
                        [-0.3543136, 51.4901816],
                        [-0.3518921, 51.4911882],
                        [-0.3508271, 51.4918802],
                        [-0.3496294, 51.4924511],
                        [-0.3481745, 51.4933494],
                        [-0.346936, 51.4939308],
                        [-0.3454666, 51.4948163],
                        [-0.3443827, 51.4957125],
                        [-0.3439829, 51.495909],
                        [-0.3430952, 51.4962548],
                        [-0.3409198, 51.4973684],
                        [-0.3392538, 51.4981087],
                        [-0.3388698, 51.4983258],
                        [-0.3376899, 51.4991769],
                        [-0.3365185, 51.4997935],
                        [-0.3354222, 51.5004293],
                        [-0.3335583, 51.5010659],
                        [-0.3293173, 51.5028808],
                        [-0.3278501, 51.503332],
                        [-0.326605, 51.5039638],
                        [-0.3254867, 51.504429],
                        [-0.3247657, 51.5046618],
                        [-0.3222145, 51.5052538],
                        [-0.3207603, 51.5057252],
                        [-0.3198604, 51.5059335],
                        [-0.3178206, 51.506498],
                        [-0.317455, 51.5065633],
                        [-0.3166387, 51.5066016],
                        [-0.3163519, 51.5065765],
                        [-0.3161221, 51.506487],
                        [-0.3159787, 51.5063698],
                        [-0.3157156, 51.5058537],
                        [-0.315312, 51.5053564],
                        [-0.3153049, 51.5051585],
                        [-0.3153827, 51.5050359],
                        [-0.3162437, 51.5042049],
                        [-0.3170389, 51.5035258],
                        [-0.3183937, 51.502437],
                        [-0.3195861, 51.5016113],
                        [-0.3208675, 51.5006053],
                        [-0.3257111, 51.4974747],
                        [-0.331246, 51.4940265],
                        [-0.3317948, 51.4937138],
                        [-0.3331674, 51.4930362],
                        [-0.3343489, 51.49216],
                        [-0.3357213, 51.4914455],
                        [-0.3367872, 51.4907523],
                        [-0.3375354, 51.4903226],
                        [-0.3388082, 51.4893177],
                        [-0.3403312, 51.4884769],
                        [-0.3414306, 51.4880268],
                        [-0.3424187, 51.487535],
                        [-0.3437788, 51.4865821],
                        [-0.3454771, 51.4856565],
                        [-0.3473995, 51.4843146],
                        [-0.3484393, 51.4838058],
                        [-0.3492166, 51.4832135],
                        [-0.3503161, 51.4825788],
                        [-0.350754, 51.4823869],
                        [-0.3516862, 51.4820738],
                        [-0.3522715, 51.4817959],
                        [-0.3530274, 51.4813685],
                        [-0.3553484, 51.4798835],
                        [-0.357264, 51.4788453],
                        [-0.358367, 51.4783842],
                        [-0.3591651, 51.477924],
                        [-0.3599476, 51.4775942],
                        [-0.3607226, 51.4772012],
                        [-0.3623646, 51.476681],
                        [-0.3631865, 51.4765797],
                        [-0.3640871, 51.47658],
                        [-0.3646758, 51.4766486],
                        [-0.3655079, 51.4768334],
                        [-0.3663142, 51.4769643]
                    ]
                ]
            ]
        }
    }]
}